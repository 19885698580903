import {
  Dictionary,
  camelCase,
  chain,
  defaults,
  endsWith,
  filter,
  flatMap,
  fromPairs,
  get,
  groupBy,
  intersection,
  isEmpty,
  isNaN,
  isNil,
  isString,
  keyBy,
  last,
  map,
  mapValues,
  max,
  maxBy,
  mean,
  meanBy,
  merge,
  min,
  omit,
  orderBy,
  pick,
  round,
  slice,
  some,
  startsWith,
  take,
  toLower,
  toPairs,
  trim,
  unzip,
  zipObject,
  zipWith,
} from "lodash";
import {
  BATTING_BODY_PARTS_EVENTS,
  BattingBodyPartMetric,
  BattingBodyPartTabs,
  BodyPartTabs,
  DATACOMP_VALUES,
  MOCK_PLAYER_PERFORMANCE_DATA,
  OBU_PROGRESSION_METRICS,
  Pitch,
  PITCHING_BODY_PARTS_EVENTS,
  PitchingBodyPartMetric,
  PitchingBodyPartTabs,
  PlayerTrends,
} from "./mockData";
import { PLAYER_DASH_VARIABLE_NAMES } from "./playerDashVariableNames";
import {
  TRENDS_DISCRETE_METRIC,
  TRENDS_TIME_SERIES_METRIC,
} from "./mockTrendsMetrics";
import {
  EXPANDED_METRICS_INFO,
  ExpandedMetricInfo,
} from "./expandedMetricsInfo";
import { LocalStorageCache, MemoryCache } from "../utils/cache";
import { getIsPitching } from "../utils/motionType";
import {
  downloadParsedCSV,
  parseCSVToArray,
  TIME_SERIES_FRIENDLY_HEADERS,
} from "../three-js/loadCSV";
import { TimeSeriesDataComp } from "./mockMetrics";
import {
  dominanceLabel,
  getOppositeSide,
  getPlayerMetricEasyName,
  metricColors,
  parseMetricValue,
  playerHandedness,
  removeSideIndicator,
  sidelessMetric,
} from "../utils/metrics";
import { truncToDecimalPlaces } from "../utils/numbers";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { mapTrend } from "../components/common/trendsConfigurations";
import { metricKeyToLabel } from "./playerDashboardWithDataComp";
import { promiseProps } from "../utils/promise";
import {
  DiscreteDataComp,
  fetchSelfCompForMetric,
  FetchTrendsCorrelationDataArg,
  PlayerPriorityCategory,
  Stats,
  AVG_SKELE_MOTION_FILE_NAME,
} from "./performanceApi.service";
import { SelfCompSelectedValue } from "../components/common/SelfMenu";
import { DataPoint } from "regression";
import { MotionType } from "../components/common/MotionType";
import { Period } from "../components/common/PeriodSelector";
import { Temperature } from "../utils/temperatureMetric";
import { EntryType } from "../components/DataCollection/DataEntryForm";
import { mapMetricFieldsIfNeeded } from "../components/InteractiveReportsTool/utils";

dayjs.extend(isBetween);

const transponseNumbers = (matrix: any[][]) =>
  unzip(matrix).map((values) =>
    values.map((it) => Number(it)).filter((it) => !isNaN(it))
  );

const filterEmptyChildren = (data: any) => {
  if (!data) {
    return [];
  }

  return data
    .map((item: any) => {
      if (item.children) {
        const filteredChildren = filterEmptyChildren(item.children);
        return filteredChildren.length > 0
          ? { ...item, children: filteredChildren }
          : null;
      } else {
        return item.value !== undefined ? item : null;
      }
    })
    .filter(Boolean);
};
const adapterCache = new MemoryCache();
const CATEGORY_TO_REMOVE = ["Angular Acceleration", "Momentum"];
const BASIC_EVENTS = [
  "ILO",
  "MKH",
  "HS",
  "IFC",
  "FFC",
  "MER",
  "BR",
  "FT",
  "MR",
  "MHS",
  "MS",
  "TFT",
  "MIR",
];
const BASIC_EVENTS_SWINGS = [
  "ILO",
  "SET",
  "DRIFT",
  "MHH",
  "LFP",
  "MSep",
  "DS",
  "FB",
  "BC",
  "FT",
  "MAX",
  "TMAX",
  "MIN",
  "TMIN",
];

export const FRAME_EVENTS_LABELS = {
  ILO: "Initial Lift Off",
  MKH: "Max Knee Height",
  HS: "Hand Separation",
  IFC: "Initial Foot Contact",
  FFC: "Firm Foot Contact",
  MER: "Max Ext. Rotation",
  BR: "Ball Release",
  FT: "Follow Through",
  MR: "Max Rotation",
  MHS: "MHS",
  MS: "Mid Stride",
  TFT: "TFT",
  MIR: "MIR",
  TMAX: "Time of Max",
  TMIN: "Time of Min",
  MAX: "Max",
  MIN: "Min",
  SET: "Stance",
  MHH: "Max Heel Height",
  LOAD: "Max Pelvis Drift",
  LFP: "Lead Foot Plant",
  MSEP: "Max Separation",
  DRIFT: "Drift",
  DS: "Down Swing",
  FB: "Flat Bat",
  MBS: "Max Bat Speed",
  PFP: "Bat Parallel to Front of Plate",
  BC: "Ball Contact",
};

export const KEY_FRAMES_PROPERTIES = {
  ILO: "KeyFrame_WB_InitialLiftOff_Frame_Frame_ILO",
  MKH: "KeyFrame_WB_MaxKneeHeight_Frame_Frame_MKH",
  MS: "KeyFrame_WB_MidStride_Frame_Frame_MS",
  FFC: "KeyFrame_WB_FirmFootContact_Frame_Frame_FFC",
  BR: "KeyFrame_WB_BallRelease_Frame_Frame_BR",
  MER: "KeyFrame_WB_MaxExternalRotation_Frame_Frame_MER",
  HS: "KeyFrame_WB_HandSeparation_Frame_Frame_HS",
  MHS: "KeyFrame_WB_MaxHandSeparation_Frame_Frame_MHS",
  IFC: "KeyFrame_WB_InitialFootContact_Frame_Frame_IFC",
  MIR: "KeyFrame_WB_MaxInternalRotation_Frame_Frame_MIR",
  TFT: "KeyFrame_WB_TrueFollowThrough_Frame_Frame_TFT",
  FT: "KeyFrame_WB_TrueFollowThrough_Frame_Frame_TFT",
  BC: "KeyFrame_WB_BallContact_Frame_Frame_BC",
};
export const KEY_FRAMES_PROPERTIES_SWINGS = {
  Set: "KF_WB_Set_Frame_Num",
  PFP: "KF_WB_PFP_Frame_Num",
  LFP: "KF_WB_LFP_Frame_Num",
  DS: "KF_WB_DS_Frame_Num",
  MSep: "KF_WB_MSep_Frame_Num",
  FB: "KF_WB_FB_Frame_Num",
  BC: "KF_WB_BC_Frame_Num",
  FT: "KF_WB_FT_Frame_Num",
  MHH: "KF_WB_MHH_Frame_Num",
  MBS: "KF_WB_MBS_Frame_Num",
  DRIFT: "KF_WB_DRIFT_Frame_Num",
};

type MetricConvention = {
  Name: string;
  "Variable Name": string;
  "full_user_data.metric.name"?: string;
  "GCP Variable Name"?: string;
  Note?: string;
  metric_units?: string;
  ymin?: number;
  ymax?: number;
  decimals?: number;
};

export type SelfCompModeOption = "discrete" | "pitchMetrics" | "timeSeries";

type FetchSelfDataProps = {
  rawResponse?: boolean;
  selfCompMode: SelfCompModeOption;
  playerId: string;
  metricId?: string;
  selfCompOptions: SelfCompSelectedValue;
  baseQuery: any;
};

type TrendsCorrelationMetric = {
  date: string;
  mean: number;
  stdDev: number;
  numPitches: number;
};

const moreEventsByKey = {
  "Angular Velocity": ["TMAX", "MAX"],
  "Linear Velocity": ["TMAX", "MAX"],
  Angle: ["MAX", "MIN"],
};

const adaptValueToEvent = (value: number, event: string) => {
  const keysFixes = {
    TMAX: (value: number) => round(value * 1000),
  };
  const functionToAdapt = get(keysFixes, event, (n: number) => n);

  return functionToAdapt(value);
};

export class PerformanceApiAdapter {
  adaptTimeSeriesMetrics(
    pitch: Pitch,
    timeSeriesData: any,
    timeSeriesLabels: any
  ) {
    const [systematicHeaders, maybeHeaders, ...transposedMetricValues] =
      timeSeriesData;

    const headers = isNaN(Number(maybeHeaders[0])) //maybe headers are not there
      ? maybeHeaders
      : TIME_SERIES_FRIENDLY_HEADERS;
    const metricValues = unzip(transposedMetricValues).map((values) =>
      values.map((it) => Number(it)).filter((it) => !isNaN(it))
    );

    const UNIT_INDEX = 2;
    const ID_INDEX = 4;
    const POSITIVE_VALUES_LABEL_INDEX = 11;
    const NEGATIVE_VALUES_LABEL_INDEX = 12;
    const ID_CATEGORY = 1;
    const metricInfo: any = {};
    timeSeriesLabels.forEach((it: any[]) => {
      if (it == timeSeriesLabels[0]) return; //skip header
      const metricKey = it[ID_INDEX];
      const unit = it[UNIT_INDEX];
      metricInfo[metricKey] = {
        unit: unit?.toLowerCase() === "deg" ? "°" : unit,
        positiveValuesLabel: it[POSITIVE_VALUES_LABEL_INDEX],
        negativeValuesLabel: it[NEGATIVE_VALUES_LABEL_INDEX],
        category: it[ID_CATEGORY],
      };
    });
    const realData = headers.map((label: string, index: number) => {
      const data = metricValues[index] || [];
      const average = mean(data);
      const minValue = (min(data) || 0) - average;
      const maxValue = (max(data) || 0) + average;
      const metricId = systematicHeaders[index];
      const type = metricInfo[metricId]?.category;
      const mappedData = data.map((it) =>
        this.changeValueUnitIfNeeded({ type }, it)
      );
      const mappedUnit = this.changeUnitIfNeeded(
        { type },
        metricInfo[metricId]?.unit || ""
      );

      return {
        id: metricId,
        pitchId: pitch.id,
        label,
        data: mappedData,
        unit: mappedUnit,
        positiveValuesLabel: metricInfo[metricId]?.positiveValuesLabel,
        negativeValuesLabel: metricInfo[metricId]?.negativeValuesLabel,
        min: this.changeValueUnitIfNeeded({ type }, minValue),
        max: this.changeValueUnitIfNeeded({ type }, maxValue),
        averageRange: {
          from: mean([
            this.changeValueUnitIfNeeded({ type }, minValue),
            this.changeValueUnitIfNeeded({ type }, average),
          ]),
          to: mean([
            this.changeValueUnitIfNeeded({ type }, maxValue),
            this.changeValueUnitIfNeeded({ type }, average),
          ]),
        },
        value: this.changeValueUnitIfNeeded({ type }, average),
      };
    });
    return realData;
  }

  async fetchTimeSeriesDataComp(
    motionType: MotionType,
    cache: MemoryCache,
    baseQuery: any
  ) {
    try {
      const { systematicMeanNames, metricsMeanValues, stdevValues } =
        await this.downloadMeansAndStdevFiles({
          cache,
          motionType,
          baseQuery,
          dataType: "timeSeries",
        });
      const timeSeriesLabels = await downloadMetricLabelsCSV(motionType);
      const adaptedTimeSeriesLabels = this.formatStringArraysToObject(
        timeSeriesLabels,
        true
      );
      const timeSeriesDataComp = systematicMeanNames.map(
        (id: string, metricIndex: number) => {
          const category = adaptedTimeSeriesLabels?.find(
            (it) => it.systematicName === id
          )?.category;

          return {
            id,
            data: metricsMeanValues[metricIndex].map(
              (mean, timeIndex: number) => {
                const stdev = stdevValues[metricIndex][timeIndex];
                const x = metricsMeanValues[0][timeIndex];
                const y = [mean - stdev, mean + stdev];

                return {
                  x,
                  y: y?.map((it) =>
                    this.changeValueUnitIfNeeded({ type: category }, it)
                  ),
                };
              }
            ),
          };
        }
      );
      return timeSeriesDataComp;
    } catch (error) {
      //Ignore temp data comp issue
      return [];
    }
  }

  async fetchDiscreteDataComp(
    motionType: MotionType,
    cache: LocalStorageCache,
    baseQuery: any,
    metrics?: string[]
  ) {
    try {
      const data = await this.fetchDiscreteDataCompFiles(
        motionType,
        cache,
        baseQuery,
        {
          dataType: "discrete",
        }
      );

      return isNil(metrics)
        ? data
        : data.filter((it) => metrics.includes(it.id));
    } catch (_error) {
      //Ignore temp data comp errors
      return [];
    }
  }

  async fetchStrideData(
    cache: LocalStorageCache,
    baseQuery: any,
    pitch: Pitch,
    motionType: MotionType,
    event: string | null
  ): Promise<{ data: any; referenceData: any }> {
    const isPitching = getIsPitching(motionType);
    const { discreteMetrics } = await this.fetchDiscreteMetrics(
      pitch,
      baseQuery,
      motionType
    );
    const formattedDiscreteMetrics =
      this.formatDiscreteMetrics(discreteMetrics);

    const PITCHING_METRICS = [
      "KM_WB_Stride_Length_ht",
      "KM_WB_Stride_Width_inches",
    ];
    const BATTNG_METRICS = [
      "POS_WB_Stride_Length_Mag",
      "POS_WB_Stride_Width_Y",
    ];
    const METRICS = isPitching ? PITCHING_METRICS : BATTNG_METRICS;

    const PITCHING_REFERENCE_METRICS = [
      "KM_R_Foot_Angle_ROT_TS_MKH",
      "Rubber_Position_y_inches",
      "KM_L_Foot_Angle_ROT_TS_FFC",
      "KM_WB_Stride_Length_legLength",
      "KM_WB_Stride_Length_inches",
      "Lead_Knee_Stability_Frontal",
    ];
    const BATTING_REFERENCE_METRICS = [
      "POS_WB_Stride_Length_Ht",
      "MHH_TTC_ms",
      "KM_F_Foot_Angle_ROT",
      "KM_B_Foot_Angle_ROT",
      "POS_R_Foot_PlateDis_Lateral",
      "POS_R_Foot_PlateDis_Forward",
    ];
    const REFERENCE_DATA_METRICS = isPitching
      ? PITCHING_REFERENCE_METRICS
      : BATTING_REFERENCE_METRICS.map((it) => {
          const currentKey = `${it}_TS_${event}`;

          return event && !isNil(discreteMetrics[currentKey]) ? currentKey : it;
        });
    const data = formattedDiscreteMetrics.filter((metric) =>
      METRICS.includes(metric.label)
    );
    const referenceData = formattedDiscreteMetrics.filter((metric) =>
      REFERENCE_DATA_METRICS.includes(metric.label)
    );

    return {
      data,
      referenceData,
    };
  }
  adaptStrideData = (
    strideData: any,
    metricsConvention: any,
    discreteDataComp: any
  ) => {
    return strideData.map((metric: any) => {
      const current = metricsConvention.find((formattedMetric: any) =>
        metric.label.includes(formattedMetric.systematicName)
      );
      const currentDataComp = discreteDataComp.find(
        (dataCompMetric: any) => metric.label === dataCompMetric.id
      ) || { averageRange: {} };
      const formatStrideData = (aValue: any) => {
        const unit = [
          "Lead_Knee_Stability_Frontal",
          "Rubber_Position_y_inches",
        ].includes(metric.label)
          ? "in"
          : current?.unit;

        const metricValue = this.changeValueUnitIfNeeded(metric, aValue);
        const value = truncToDecimalPlaces(metricValue, 1);
        const label =
          metric.label === "KM_R_Foot_Angle_ROT_TS_MKH"
            ? "Back Foot Angle"
            : metric.label === "KM_L_Foot_Angle_ROT_TS_FFC"
            ? "Lead Foot Angle"
            : current?.easyName;

        return { unit, label, value, decimals: current?.decimals };
      };
      const formatStrideValue = (aValue: any) => {
        return formatStrideData(aValue).value;
      };

      const formattedDataComp = {
        ...currentDataComp,
        min: formatStrideValue(currentDataComp.min),
        max: formatStrideValue(currentDataComp.max),
        mean: formatStrideValue(currentDataComp.mean),
        stdev: formatStrideValue(currentDataComp.stdev),
        averageRange: {
          from: formatStrideValue(currentDataComp.averageRange.from),
          to: formatStrideValue(currentDataComp.averageRange.to),
        },
      };

      return {
        ...metric,
        ...formattedDataComp,
        ...formatStrideData(metric.value),
      };
    });
  };

  getShouldHandleInches = (metric: any) =>
    (metric?.type || "").includes("Position") ||
    metric?.bodyPart === "Head & Hand";

  changeValueUnitIfNeeded(metric: any, aValue: number) {
    const percentageValueMetrics = [
      "KM_WB_Stride_Length_legLength",
      "KM_WB_Stride_Length_ht",
      "KM_G_Bat_OnPlane_Perc",
      "POS_WB_Stride_Length_Ht",
    ];
    //Check if value isn't already expressed in percentage
    if (percentageValueMetrics.includes(metric.label) && aValue < 10) {
      return aValue * 100;
    }

    const shouldHandleInches = this.getShouldHandleInches(metric);

    if (shouldHandleInches) {
      const mmToInchesFactor = 0.0393701;
      return aValue * mmToInchesFactor;
    }

    return aValue;
  }

  changeUnitIfNeeded(metric: any, unit: string) {
    const shouldHandleInches = this.getShouldHandleInches({
      ...metric,
      unit,
    });

    if (shouldHandleInches) {
      return "in";
    }

    return unit;
  }

  async fetchStuffDataComp(
    motionType: MotionType,
    cache: LocalStorageCache,
    baseQuery: any,
    pitch: Pitch
  ) {
    const { discreteMetrics } = await this.fetchDiscreteMetrics(
      pitch,
      baseQuery
    );
    const discreteDataComp = await this.fetchDiscreteDataComp(
      motionType,
      cache,
      baseQuery
    );
    const armSlotDataComp = discreteDataComp.find(
      (discrete: any) => discrete.id === "ArmSlot_Apex"
    );

    const stuffDiscreteDataComp = await this.fetchDiscreteDataCompFiles(
      motionType,
      cache,
      baseQuery,
      {
        dataType: "pitchingMetrics",
      }
    );

    return [
      ...stuffDiscreteDataComp,
      { ...armSlotDataComp, value: Number(discreteMetrics.ArmSlot_Apex) },
    ];
  }

  async fetchDiscreteDataCompFiles(
    motionType: MotionType,
    cache: LocalStorageCache,
    baseQuery: any,
    options: { dataType: string }
  ): Promise<DiscreteDataComp[]> {
    try {
      return cache.withCache(
        `discreteDataComp-${options.dataType}-${motionType}`,
        async () => {
          const { systematicMeanNames, metricsMeanValues, stdevValues } =
            await this.downloadMeansAndStdevFiles({
              motionType,
              cache,
              baseQuery,
              ...options,
            });

          const discreteDataComp = systematicMeanNames.map(
            (id: string, metricIndex: number) => {
              const metric = { label: id };
              const mean = this.changeValueUnitIfNeeded(
                metric,
                metricsMeanValues[metricIndex][0]
              );
              const doubleStdev = this.changeValueUnitIfNeeded(
                metric,
                stdevValues[metricIndex][0]
              );
              const stdev = doubleStdev * 0.5;
              return {
                id,
                mean,
                stdev,
                min: mean - doubleStdev,
                max: mean + doubleStdev,
                averageRange: { from: mean - stdev, to: mean + stdev },
              };
            }
          );
          return discreteDataComp;
        }
      );
    } catch (error) {
      // @ts-ignore
      return error;
    }
  }

  async downloadMeansAndStdevFiles({
    dataType,
    motionType,
    baseQuery,
    cache,
  }: {
    dataType: string;
    motionType: MotionType;
    baseQuery: any;
    cache: MemoryCache;
  }) {
    const dataCompEndpoint = (aggregation: string) =>
      `/api/external/performance-dash/dataComp/${
        motionType === MotionType.Batting && dataType != "pitchingMetrics" //TODO: SEE IF THIS IS NEEDED
          ? "hitting/"
          : ""
      }${dataType}/${aggregation}`;
    const dataCompMeansPromise = cache.withCache(
      `${dataType}-means-${motionType}`,
      async () => await this.downloadCSV(baseQuery, dataCompEndpoint("mean"))
    );
    const dataCompStdevsPromise = cache.withCache(
      `${dataType}-stdevs-${motionType}`,
      async () => await this.downloadCSV(baseQuery, dataCompEndpoint("stdDev"))
    );
    const [dataCompTimeSeriesMeans, dataCompTimeSeriesStdevs] =
      await Promise.all([dataCompMeansPromise, dataCompStdevsPromise]);

    const [systematicMeanNames, ...transposedMeanValues] =
      dataCompTimeSeriesMeans;
    const metricsMeanValues = transponseNumbers(transposedMeanValues);

    //eslint-disable-next-line no-unused-vars
    const [systematicStdevNames, ...transposedStdevValues] =
      dataCompTimeSeriesStdevs;

    const stdevValues = transponseNumbers(transposedStdevValues);
    return { systematicMeanNames, stdevValues, metricsMeanValues };
  }

  buildStats(sessions: { value: number }[]): Stats {
    const values = map(sessions, "value");
    const low = min(values) ?? 0;
    const high = max(values) ?? 0;
    const average = mean(values) ?? 0;
    return {
      low,
      high,
      mean: average,
      stdDev: this.getStandardDeviation(values),
    };
  }

  async fetchAssessmentConventions(entryType: EntryType, cache: MemoryCache) {
    try {
      const assessmentConventions = await cache.withCache(
        `assessment-conventions-${entryType}`,
        async () => {
          const suffix = entryType === "team" ? "Team" : "";
          const assessments = await downloadParsedCSV(
            `datacollection/data/assessmentConventions${suffix}.csv`
          );
          const assessmentsFiltered = assessments.filter((assessment: any) => {
            return !assessment.includes("bcmp_skin_iliac");
          });

          const groupedAssessment = groupBy(
            slice(assessmentsFiltered, 1),
            (assessment: any) => assessment[0]
          );

          return mapValues(groupedAssessment, (assessment) => {
            const groups: any = groupBy(assessment, (group: any) => group[1]);
            return mapValues(groups, (group) =>
              group
                ?.map((group: any) =>
                  group.map((it: any) => it?.replace("\r", ""))
                )
                .map((group: any) => ({
                  assessmentType: group[0],
                  group: group[1],
                  label: group[2],
                  key: group[3],
                  lowerValue: Number(group[4]),
                  upperValue: Number(group[5]),
                  options: group[6]
                    ?.split("|")
                    ?.filter(Boolean)
                    ?.map((option: string) => ({
                      value: toLower(option),
                      label: option,
                    })),
                  calculated: group[7] === "true",
                }))
            );
          });
        }
      );

      return assessmentConventions;
    } catch (error) {
      return error;
    }
  }
  async fetchTimeSeriesData(
    pitch: Pitch,
    motionType: MotionType,
    cache: MemoryCache,
    baseQuery: any
  ) {
    try {
      const timeSeriesDataPromise = cache.withCache(
        `pitch-${pitch.id}-raw-timeSeries`,
        async () => {
          const id = pitch.id.toString();
          const endpointPath = regularOrAvgEndpointPath(
            id,
            motionType,
            pitch,
            "timeSeries"
          );
          return await this.downloadCSV(
            baseQuery,
            `api/external/performance-dash/${endpointPath}`
          );
        }
      );
      const timeSeriesLabelsPromise = downloadMetricLabelsCSV(motionType);
      const [timeSeriesData, timeSeriesLabels] = await Promise.all([
        timeSeriesDataPromise,
        timeSeriesLabelsPromise,
      ]);
      const data = this.adaptTimeSeriesMetrics(
        pitch,
        timeSeriesData,
        timeSeriesLabels
      ).filter((it: any) => it.id != "Frame" && it.id != "Time");
      return data;
    } catch (error) {
      return error;
    }
  }

  async keyFrames(pitch: Pitch, motionType: MotionType, baseQuery: any) {
    const { discreteMetrics, isSamplePitch, isSecondaryPitch } =
      await this.fetchDiscreteMetrics(pitch, baseQuery, motionType);
    const keyFrames =
      motionType === MotionType.Batting
        ? KEY_FRAMES_PROPERTIES_SWINGS
        : KEY_FRAMES_PROPERTIES;
    return map(keyFrames, (property, label) => {
      const frame = Number(
        discreteMetrics[property] || discreteMetrics[property + "_C1"]
      );
      if (label !== "BR") {
        return { label, frame };
      }
      return {
        label,
        frame: isSamplePitch ? 140 : isSecondaryPitch ? 360 : frame,
      };
    });
  }

  async fetchDiscreteMetrics(
    pitch: Pitch,
    baseQuery: any,
    motionType: MotionType = MotionType.Pitching
  ) {
    //We don't use sample data anymore
    const isSamplePitch = false;
    const isSecondaryPitch = false;
    const id = pitch.id.toString();
    const endpointPath = regularOrAvgEndpointPath(
      id,
      motionType,
      pitch,
      "discrete"
    );
    const discreteMetrics = isSamplePitch
      ? await this.downloadCSVObjectFromSample()
      : await this.downloadCSVObjectFromApi(
          baseQuery,
          `api/external/performance-dash/${endpointPath}`,
          ","
        );
    return { discreteMetrics, isSamplePitch, isSecondaryPitch };
  }

  objectToStringArray = (object: any): string[] => {
    const values: string[] = Object.values(object);
    const stringValue: string = values[0];

    return stringValue.split(",");
  };

  fixUnits(unit?: string) {
    const unitsDictionary: { [unit: string]: string } = {
      Deg: "°",
      "Deg/s": "°/s",
      Joules: "J",
      Velocity: "Vel",
      Position: "Pos",
      "m^2/kg": "Js",
    };

    if (!unit) {
      return "";
    }

    const fixedUnit = unitsDictionary[unit];

    return fixedUnit ? fixedUnit : unit;
  }

  eventKeyToLabel(event: string): string {
    // @ts-expect-error
    return FRAME_EVENTS_LABELS[event.toUpperCase()] || event;
  }
  adaptSequenceTimeSeries(
    data: any,
    discreteDataComp: any,
    motionType: MotionType
  ) {
    const sequenceMetrics = this.sequenceMetrics(motionType);
    const filteredData = data
      .filter((metric: any) =>
        sequenceMetrics.some((basicMetric: any) =>
          metric.id.includes(basicMetric)
        )
      )
      .map((metric: any, index: number) => {
        const dataCompMetric = discreteDataComp.find(
          (compMetric: any) =>
            compMetric?.id.includes(metric?.id) &&
            endsWith(compMetric?.id, "_MS")
        );

        return {
          ...metric,
          min: Math.round(dataCompMetric?.min),
          max: Math.round(dataCompMetric?.max),
          averageRange: {
            from: Math.round(dataCompMetric?.averageRange?.from),
            to: Math.round(dataCompMetric?.averageRange?.to),
          },
          color: metricColors[index],
          unit: this.fixUnits(metric.unit),
        };
      });
    return filteredData;
  }
  async fetchSequenceRangeData(
    pitch: Pitch,
    baseQuery: any,
    motionType: MotionType
  ) {
    const { discreteMetrics: rawDiscreteMetrics } =
      await this.fetchDiscreteMetrics(pitch, baseQuery, motionType);
    const discreteMetrics: any = rawDiscreteMetrics;
    const sequenceTimeSeries = this.sequenceMetrics(motionType);
    const sequenceDiscretePairs = sequenceTimeSeries.map((timeSeriesMetric) => [
      `${timeSeriesMetric}_MAX`,
      `${timeSeriesMetric}_TMAX`,
    ]);

    const sequenceDiscreteMetrics = sequenceDiscretePairs.map((it) =>
      it.map((metricName: string) => ({
        label: metricName,
        value:
          Number(discreteMetrics[metricName]) *
          (endsWith(metricName, "TMAX") ? 1000 : 1), //Convert time to ms
      }))
    );
    return sequenceDiscreteMetrics;
  }

  sequenceMetrics(motionType: MotionType) {
    return motionType === MotionType.Batting
      ? [
          "KM_WB_Pelvis_AngVel_ROT_TS",
          "KM_WB_Trunk_AngVel_Mag_TS",
          "KM_F_Forearm_AngVel_MagNoRoll_TS",
          "KM_F_Bicep_AngVel_MagNoRoll_TS",
          "KM_G_Bat_RotSpeed_MagDeg_TS",
        ]
      : [
          "KM_WB_Pelvis_Velocity_ROT_TS",
          "KM_WB_Trunk_Velocity_ROT_TS",
          "KM_WB_Trunk_Velocity_ForwardTilt_TS",
          "KM_L_Knee_Velocity_Flexion_TS",
        ];
  }

  async fetchThrowEventsData(
    pitch: Pitch,
    baseQuery: any,
    section: string,
    motionType?: MotionType
  ) {
    const { discreteMetrics } = await this.fetchDiscreteMetrics(
      pitch,
      baseQuery,
      motionType
    );

    const filteredData = filter(toPairs(discreteMetrics), ([key]) =>
      endsWith(key, `_${section}`)
    ).map(([systematicName, value]) => ({
      systematicName,
      value: Number(value),
    }));

    return filteredData;
  }

  getDefaultValue(metric: any) {
    const { value } = metric;

    return {
      max: value * 2,
      min: value / 2,
      averageRange: { from: value - 2, to: value + 2 },
    };
  }

  mergeDiscreteDataCompMetrics(data: any[], compData: any[]) {
    return data.map((metric) => {
      const currentCompMetric =
        compData.find((compMetric) => metric?.id === compMetric?.id) ||
        this.getDefaultValue(metric);

      const { min, max, averageRange } = currentCompMetric;

      return {
        ...metric,
        max: Math.round(max),
        min: Math.round(min),
        averageRange: {
          from: Math.round(averageRange.from),
          to: Math.round(averageRange.to),
        },
      };
    });
  }

  formatThrowEventData(
    data: any[],
    formattedMetrics: any[],
    compData: any[] = []
  ) {
    const dataWithLabels = data.map((metric) => {
      const { name, unit, decimals } =
        formattedMetrics.find((formattedMetric: any) =>
          metric.systematicName.includes(formattedMetric.systematicName)
        ) ?? {};

      return {
        ...metric,
        id: metric.systematicName,
        value: Math.floor(metric.value),
        label: name ?? metric.systematicName,
        unit: unit ?? "",
        decimals,
      };
    });

    return this.mergeDiscreteDataCompMetrics(dataWithLabels, compData);
  }

  findDiscreteMetricLabel(
    systematicName: string,
    labels: any[]
  ): { unit?: string; name?: string } {
    const selected = labels.find(
      // eslint-disable-next-line no-unused-vars
      ([_index, _category, _units, _name, labelSystematicName]) =>
        systematicName?.includes(labelSystematicName) ||
        labelSystematicName?.includes(systematicName)
    );
    return isNil(selected)
      ? { unit: undefined, name: undefined }
      : { unit: this.fixUnits(selected[2]), name: selected[3] };
  }

  formatBodyPartsData(
    data: any[],
    labels: any[],
    bodyPart: PitchingBodyPartTabs | BattingBodyPartTabs,
    referenceData: PitchingBodyPartMetric | BattingBodyPartMetric,
    discreteDataComp: any[],
    motionType: MotionType
  ) {
    // @ts-expect-error
    const currentBodyPart: string[] = referenceData[bodyPart];

    const events = {
      [MotionType.Pitching]: PITCHING_BODY_PARTS_EVENTS,
      [MotionType.Batting]: BATTING_BODY_PARTS_EVENTS,
    };

    return (
      currentBodyPart
        .map((metricName) => {
          const label = labels.find(([, , , name]) =>
            name?.includes(metricName)
          );

          const filteredData = data.filter((metric) =>
            metric?.systematicName?.includes(label?.[4])
          );
          const mappedData = filteredData.map((it) => ({
            ...it,
            value: this.changeValueUnitIfNeeded({ bodyPart }, it.value),
          }));

          return {
            id: label?.[4],
            key: label?.[4],
            name: metricName,
            bodyPart,
            unit: this.changeUnitIfNeeded(
              { bodyPart },
              this.fixUnits(label?.[2])
            ),
            events: this.getEvents(mappedData, events[motionType]),
          };
        })
        //Filter metrics without complete data
        .filter((it) => it.id)
        .map((bodyPartMetric) => {
          const dataComp = discreteDataComp
            .filter((discrete) => {
              return discrete.id.includes(bodyPartMetric.id);
            })
            .map((it) => {
              const mapCurrentValue = (value: number) =>
                this.changeValueUnitIfNeeded({ ...bodyPartMetric }, value);

              return {
                ...it,
                event: last(it.id.split("_")),
                mean: mapCurrentValue(it.mean),
                max: mapCurrentValue(it.max),
                min: mapCurrentValue(it.min),
                stdev: mapCurrentValue(it.stdev),
                averageRange: {
                  from: mapCurrentValue(it.averageRange.from),
                  to: mapCurrentValue(it.averageRange.to),
                },
              };
            });

          return {
            dataComp,
            ...bodyPartMetric,
          };
        })
    );
  }

  fixBodyPartSection(section: string) {
    const fixedSections = {
      "Throwing arm": "arm",
      "Glove arm": "glove",
      "Lead leg": "leg",
      "Back leg": "leg",
    };

    // @ts-expect-error
    return fixedSections[section];
  }

  getEvents(data: any[], events: string[]) {
    const mappedEvents = {};

    events.forEach((event) => {
      Object.assign(mappedEvents, {
        [event.toLowerCase()]: this.fixEventValue(event, data),
      });
    });

    return mappedEvents;
  }

  fixEventValue(eventLabel: string, events: any[]): number {
    const event: any = events.find((event) =>
      event?.systematicName?.includes(eventLabel)
    );

    return event !== undefined ? event.value : 0;
  }

  async fetchBodyPartsData(
    pitch: Pitch,
    bodyPart: BodyPartTabs,
    baseQuery: any,
    motionType: MotionType
  ) {
    const { discreteMetrics } = await this.fetchDiscreteMetrics(
      pitch,
      baseQuery,
      motionType
    );

    const mapValue = (value: number) => {
      if (bodyPart === BodyPartTabs.HeadAndHand) {
        return value;
      }

      return Math.floor(Number(value));
    };

    return toPairs(discreteMetrics).map(([systematicName, value]: any[]) => {
      return {
        systematicName,
        value: mapValue(value),
      };
    });
  }

  async downloadCSV(baseQuery: any, url: string, delimiter?: string) {
    const response = await baseQuery(url);
    return parseCSVToArray(response.error?.data, delimiter);
  }

  async downloadCSVObjectFromSample() {
    return this.parseCSVObjectFromRequest(
      downloadParsedCSV(`3d/data/cbma_discrete.csv`)
    );
  }

  async downloadCSVObjectFromApi(
    baseQuery: any,
    url: string,
    delimiter?: string
  ) {
    return this.parseCSVObjectFromRequest(
      this.downloadCSV(baseQuery, url, delimiter)
    );
  }

  async parseCSVObjectFromRequest(request: Promise<any>) {
    const csvArray = await request;
    const csvObject = zipObject(csvArray[0], csvArray[1]);
    return csvObject;
  }

  formatMetricsConvention(metrics: string[][], labels?: any[]) {
    return metrics
      .slice(1)
      .map(
        ([
          ,
          type,
          bodyPart,
          easyName,
          systematicName,
          ,
          ,
          decimals,
        ]: string[]) => {
          const { unit = undefined, name = undefined } = isNil(labels)
            ? {}
            : this.findDiscreteMetricLabel(systematicName, labels);
          return {
            type,
            bodyPart,
            easyName,
            systematicName,
            unit,
            name,
            decimals,
          };
        }
      );
  }

  formatMetricsLabels(metrics: string[][]) {
    return metrics.slice(1).map(
      ([
        // eslint-disable-next-line no-unused-vars
        _index,
        category,
        units,
        name,
        systematicName,
        kineticKinematic,
        bodyLocation,
        bodyPart,
        variable,
        directionMagnitude,
        timestamp,
        positiveValuesLabel,
        negativeValuesLabel,
        decimals,
      ]: string[]) => ({
        category,
        units: this.fixUnits(units),
        name,
        systematicName,
        kineticKinematic,
        bodyLocation,
        bodyPart,
        variable,
        directionMagnitude,
        timestamp,
        positiveValuesLabel,
        negativeValuesLabel,
        decimals: !isNaN(Number(decimals)) ? Number(decimals) : 0,
      })
    );
  }

  formatDiscreteMetrics(discreteMetrics: any) {
    return Object.keys(discreteMetrics).map((key: string) => ({
      label: key,
      value: Number(discreteMetrics[key]),
    }));
  }

  metricsFormatByMetricId(
    data: any[],
    discreteMetrics: any,
    formattedMetricsLabels: any[],
    metricId: string
  ) {
    const groupedByType = groupBy(data, "type");
    return Object.keys(groupedByType).map((key) => {
      const groupedByBodyPart = groupBy(groupedByType[key], "bodyPart");

      return {
        key: key,
        label: key,
        children: Object.keys(groupedByBodyPart).map((key) => {
          const metrics = groupedByBodyPart[key].map((metric) => {
            const value =
              discreteMetrics[`${metric.systematicName}TS_${metricId}`];

            const currentLabel = formattedMetricsLabels.find((it) =>
              it?.systematicName?.includes(metric?.systematicName)
            );

            return {
              key: metric?.systematicName,
              label: metric?.easyName || "",
              value: Math.floor(Number(value)),
              unit: currentLabel?.units || "",
            };
          });

          return {
            key: key,
            label: key,
            children: metrics,
          };
        }),
      };
    });
  }

  metricsFormatWithoutMetricId(
    data: any[],
    discreteMetrics: any,
    formattedMetricsLabels: any[],
    discreteDataComp: any[],
    motionType: MotionType
  ) {
    const groupedByType: any = omit(groupBy(data, "type"), CATEGORY_TO_REMOVE);
    const formattedDiscreteMetrics =
      this.formatDiscreteMetrics(discreteMetrics);

    const parsedMetrics = Object.keys(groupedByType).map((key) => ({
      key: key,
      label: key,
      children: groupedByType[key]
        .map((metric: any) => {
          const filteredMetrics = formattedDiscreteMetrics.filter((it) => {
            const event = last(it.label.split("_"));
            const basicEvents =
              motionType === MotionType.Batting
                ? BASIC_EVENTS_SWINGS
                : BASIC_EVENTS;
            const EVENTS = [...basicEvents, ...get(moreEventsByKey, key, [])];
            if (key === "Misc Metrics") {
              return it.label.includes(metric.systematicName);
            }
            return (
              it.label.includes(metric.systematicName) &&
              EVENTS.includes(event || "")
            );
          });
          const currentLabel = formattedMetricsLabels.find((it) =>
            (it?.systematicName || "").includes(metric?.systematicName)
          );

          const discreteDataCompValues = discreteDataComp.find((compMetric) =>
            compMetric?.id.includes(metric?.systematicName)
          );
          if (key === "Misc Metrics") {
            const metricEndName = last(metric.easyName.split(" "));
            if (
              isEmpty(filteredMetrics) ||
              isNil(discreteDataCompValues) ||
              metricEndName === "Frame" ||
              metric.systematicName === "KM_WB_Pitch_Time_FM-TFT"
            ) {
              return;
            }
            const formatIfStrideValue = (aValue: number) => {
              const isStride = metric.systematicName.includes("KM_WB_Stride_");
              if (!isStride) return aValue;
              const metricValue = [
                "KM_WB_Stride_Length_ht",
                "KM_WB_Stride_Length_legLength",
              ].includes(metric.systematicName)
                ? aValue * 100
                : aValue;
              return truncToDecimalPlaces(metricValue, 1);
            };

            const mappedDecimals = !isNil(metric?.decimals)
              ? Number(metric?.decimals)
              : !isNil(currentLabel?.decimals)
              ? currentLabel?.decimals
              : 0;

            return {
              ...discreteDataCompValues,
              key: metric.systematicName,
              label: metric.easyName,
              value: formatIfStrideValue(filteredMetrics[0]?.value),
              unit: currentLabel?.unit || "",
              decimals: mappedDecimals,
              min: formatIfStrideValue(
                truncToDecimalPlaces(
                  discreteDataCompValues?.min,
                  mappedDecimals
                )
              ),
              max: formatIfStrideValue(
                truncToDecimalPlaces(
                  discreteDataCompValues?.max,
                  mappedDecimals
                )
              ),
              averageRange: {
                from: formatIfStrideValue(
                  truncToDecimalPlaces(
                    discreteDataCompValues?.averageRange?.from,
                    mappedDecimals
                  )
                ),
                to: formatIfStrideValue(
                  truncToDecimalPlaces(
                    discreteDataCompValues?.averageRange?.to,
                    mappedDecimals
                  )
                ),
              },
            };
          }

          return {
            key: metric.easyName,
            label: metric.easyName,
            ...discreteDataCompValues,
            children: filteredMetrics.map((it) => {
              const dataCompMetric =
                discreteDataComp.find(
                  (compMetric) => compMetric?.id === it?.label
                ) || discreteDataCompValues;
              const event = last(it.label.split("_")) || "";
              const mappedDecimals = !isNil(metric?.decimals)
                ? Number(metric?.decimals)
                : !isNil(currentLabel?.decimals)
                ? currentLabel?.decimals
                : 0;

              const truncToDecimalPlacesIfNeeded = (number: number) =>
                this.getShouldHandleInches(metric)
                  ? number
                  : truncToDecimalPlaces(number, mappedDecimals);

              const mapCurrentValue = (value: number) => {
                return truncToDecimalPlacesIfNeeded(
                  this.changeValueUnitIfNeeded(
                    { ...metric, ...it },
                    adaptValueToEvent(value, event)
                  )
                );
              };

              const mappedUnit = this.changeUnitIfNeeded(
                { ...metric, ...it },
                event === "TMAX" ? "ms" : currentLabel?.units || ""
              );

              return {
                systematicName: event,
                key: `${metric.systematicName}TS_${event}`,
                label: this.eventKeyToLabel(event),
                value: mapCurrentValue(it?.value),
                min: mapCurrentValue(dataCompMetric?.min),
                max: mapCurrentValue(dataCompMetric?.max),
                averageRange: {
                  from: mapCurrentValue(dataCompMetric?.averageRange?.from),
                  to: mapCurrentValue(dataCompMetric?.averageRange?.to),
                },
                unit: mappedUnit,
                decimals: mappedDecimals,
              };
            }),
          };
        })
        .filter(Boolean),
    }));
    return filterEmptyChildren(parsedMetrics);
  }

  adaptDataComp = (data: any[]) =>
    data.map((metric: TimeSeriesDataComp) => ({
      ...metric,
      label: "Comp Data Metric",
      data: metric.data.map(({ x, y }) => ({
        x,
        low: y[0],
        high: y[1],
      })),
    }));

  adaptPitchVideos(pitch: Pitch) {
    if (!isEmpty(pitch.videos))
      return pitch.videos
        ?.filter(
          (it) =>
            it.videoUrl.includes("skeleton") &&
            it.videoUrl.includes("ffmpeg") &&
            it.angle
        )
        .map((it) => ({ ...it, angle: it.angle }));
    return [];
  }

  filterDataByMetricGroupPrefix(
    metric: string,
    data: any,
    includeExtraneousMetrics: boolean = false
  ) {
    const keysToOmit = includeExtraneousMetrics
      ? []
      : Object.keys(data).filter((key) => {
          const exists = !isNil(
            PLAYER_DASH_VARIABLE_NAMES.find((it) => it === key)
          );

          return !key.startsWith(metric) || !exists;
        });
    const valuesWithOmittedKeys = omit(data, keysToOmit);

    return mapValues(valuesWithOmittedKeys, function (measurement) {
      if (isEmpty(measurement)) {
        return Array.from(Array(5).keys()).map(() => ({
          value: null,
          date: "",
          updatedAt: "",
          playerId: "",
          id: "",
        }));
      } else {
        return measurement;
      }
    });
  }

  filterDataByMetricGroupPrefixForPeriod(metric: string, data: any) {
    const keysToOmit = Object.keys(data).filter(
      (key) => !key.startsWith(metric)
    );
    const ommitedValues: any = omit(data, keysToOmit);
    const metrics = {};

    Object.keys(ommitedValues).forEach((key) => {
      Object.assign(metrics, { [key]: ommitedValues[key] });
    });

    return metrics;
  }

  adaptObuMetrics(playerMetrics: any) {
    const mappedMetrics = {};

    Object.keys(playerMetrics).forEach((key: any) => {
      const sidelessKey = key.replace("_left", "").replace("_right", "");
      const isProgression = OBU_PROGRESSION_METRICS.includes(sidelessKey);

      Object.assign(mappedMetrics, {
        [key]: playerMetrics[key].map((metric: any) => ({
          ...metric,
          isProgression,
        })),
      });
    });

    return mappedMetrics;
  }

  groupPlayerPerformanceData(
    playerMetrics: any,
    includeExtraneousMetrics: boolean = false
  ) {
    return {
      msk: this.filterDataByMetricGroupPrefix("msk", playerMetrics),
      rom: this.filterDataByMetricGroupPrefix("rom", playerMetrics),
      power: this.filterDataByMetricGroupPrefix("pwr", playerMetrics),
      speed: this.filterDataByMetricGroupPrefix("spd", playerMetrics),
      bodyComp: this.filterDataByMetricGroupPrefix("bcmp", playerMetrics),
      vision: merge(
        this.filterDataByMetricGroupPrefix("visl", playerMetrics),
        this.filterDataByMetricGroupPrefix("visn", playerMetrics)
      ),
      workload: this.filterDataByMetricGroupPrefix("gps", playerMetrics),
      obu: this.adaptObuMetrics(
        this.filterDataByMetricGroupPrefix("obu", playerMetrics)
      ),
      ...(includeExtraneousMetrics
        ? {
            perf: this.filterDataByMetricGroupPrefix(
              "perf",
              playerMetrics,
              includeExtraneousMetrics
            ),
          }
        : {}),
    };
  }

  groupPlayerPerformanceDataForPeriod(playerMetrics: any) {
    return {
      msk: this.filterDataByMetricGroupPrefixForPeriod("msk", playerMetrics),
      rom: this.filterDataByMetricGroupPrefixForPeriod("rom", playerMetrics),
      power: this.filterDataByMetricGroupPrefixForPeriod("pwr", playerMetrics),
      speed: this.filterDataByMetricGroupPrefixForPeriod("spd", playerMetrics),
      bodyComp: this.filterDataByMetricGroupPrefixForPeriod(
        "bcmp",
        playerMetrics
      ),
      vision: this.filterDataByMetricGroupPrefixForPeriod("vis", playerMetrics),
      workload: this.filterDataByMetricGroupPrefixForPeriod(
        "gps",
        playerMetrics
      ),
    };
  }

  mostRecentMetricValues(playerMetrics: any) {
    const mostRecentMetricValues: any = {};
    const player_metrics = orderBy(playerMetrics, (metric) => metric?.date, [
      "desc",
    ]);
    const allPlayerMetrics = Object.keys(
      player_metrics.reduce((acc, curr) => ({ ...acc, ...curr }), {})
    );
    const metricKeys = allPlayerMetrics.filter(
      (it) =>
        !["_id", "date", "created_at", "updated_at", "player_id"].includes(it)
    );

    //Remove broken records
    player_metrics.forEach((it) => {
      if (it.pwr_row_test_combined == "0") delete it.pwr_row_test_combined;
      if (!Number(it.bcmp_weight)) delete it.bcmp_weight;
      const nanKeys = Object.keys(it).filter((key) => it[key] === "NaN");
      nanKeys.forEach((nanKey) => delete it[nanKey]);
    });

    metricKeys.forEach((metricKey) => {
      const metricMostRecentValues = take(
        player_metrics
          .filter((it: any) => it[metricKey])
          .map((it: any) => ({
            id: it._id,
            date: dateToISO(it.created_at || it.date),
            playerId: it.player_id,
            updatedAt: dateToISO(it.updated_at || it.date),
            value: it[metricKey],
          })),
        5
      );
      mostRecentMetricValues[metricKey] = metricMostRecentValues;
    });
    return mostRecentMetricValues;
  }

  findMetricByVariableName = (metric: string, data: MetricConvention[]) => {
    return data.find((it: MetricConvention) =>
      metric.includes(it["Variable Name"])
    );
  };

  isPlayerPitcher(player: any) {
    return isPlayerPitcher(player);
  }

  async adaptPlayerPerformanceData(
    rawPlayerMetrics: any,
    metricConventions: any,
    timePeriod: { startDate: string; endDate: string },
    trendsData: any,
    includeExtraneousMetrics: boolean = false
  ) {
    const adaptedMetricConventions: MetricConvention[] =
      this.formatStringArraysToObject(metricConventions);

    const playerMetrics = !isEmpty(rawPlayerMetrics)
      ? rawPlayerMetrics
      : MOCK_PLAYER_PERFORMANCE_DATA;
    this.completeMissingPlayerDashMetrics(playerMetrics);

    const playerMetricsInPeriod = this.filterMetricsInPeriod(
      playerMetrics,
      timePeriod
    );

    const mostRecentMetricValues = this.mostRecentMetricValues(
      playerMetricsInPeriod
    );

    const data: any = this.groupPlayerPerformanceData(
      mostRecentMetricValues,
      includeExtraneousMetrics
    );

    const getAggregatesByMetricKey = (key: string) => {
      const values = playerMetrics
        .map((metrics: any) => metrics[key])
        .filter((it: number) => !isNil(it) && isFinite(it))
        .map((it: any) => Number(it));

      return {
        average: mean(values),
        max: max(values),
        min: min(values),
      };
    };

    const metrics: any = data;
    const metricsKeys = Object.keys(metrics);

    const metricsGroups = metricsKeys.map((key: any) => {
      return {
        key,
        label: getPlayerMetricEasyName(key),
        metrics: Object.keys(data[key])
          .map((metricKey) => {
            return this.buildPlayerDashboardMetricByMetricKey(
              metricKey,
              adaptedMetricConventions,
              getAggregatesByMetricKey,
              data,
              key,
              trendsData
            );
          })
          .filter((it) => includeExtraneousMetrics || it.label != it.metric),
        //This filters extraneous metrics
      };
    });
    return metricsGroups;
  }

  filterMetricsInPeriod(
    playerMetrics: any,
    timePeriod: { startDate: string; endDate: string }
  ) {
    return playerMetrics.filter((it: any) =>
      dayjs(it.date, "YYYY-MM-DD").isBetween(
        timePeriod.startDate,
        timePeriod.endDate
      )
    );
  }

  completeMissingPlayerDashMetrics(playerMetrics: any) {
    const defaultVariables = fromPairs(
      zipWith(PLAYER_DASH_VARIABLE_NAMES, (it: string) => [it, null])
    );

    playerMetrics.forEach((it: any) => {
      defaults(it, defaultVariables);
    });
  }

  buildPlayerDashboardMetricByMetricKey(
    metricKey: string,
    adaptedMetricConventions: MetricConvention[],
    getAggregatesByMetricKey: (key: string) => {
      average: number;
      max: unknown;
      min: unknown;
    },
    data: any,
    key: any,
    trendsData: any
  ) {
    const currentMetric = this.findMetricByVariableName(
      metricKey,
      adaptedMetricConventions
    );
    const aggregates = getAggregatesByMetricKey(metricKey);

    const decimals = Number(currentMetric?.decimals);
    const currentMetricsInData = data[key][metricKey];
    const values = currentMetricsInData.map((it: any) => ({
      ...it,
      value: this.toDecimalsOrDefault(it?.value, decimals),
    }));

    const isProgression =
      key === "obu" ? currentMetricsInData[0]?.isProgression : undefined;
    const trend = mapTrend((trendsData || {})[metricKey]);
    return {
      metric: metricKey,
      label: currentMetric?.Name || metricKey,
      value: values[0],
      history: values,
      average: aggregates.average,
      trend, //PLAYER PAGE TREND VALUE
      yBounds: {
        max: aggregates.max,
        min: aggregates.min,
      },
      unit: this.getMetricConventionUnit(currentMetric?.metric_units || ""),
      decimals,
      isProgression,
    };
  }

  getMetricConventionUnit(unit: string) {
    const UNITS: { [key: string]: string } = {
      Degrees: "°",
      Newtons: "N",
      Inches: "in",
      Pounds: "lbs",
    };

    const mappedUnit: string = UNITS[unit];

    return mappedUnit;
  }

  async getTrendsDiscreteMetrics() {
    return TRENDS_DISCRETE_METRIC.map((it) => ({ ...it, metric: it.id }));
  }

  async getTrendsDiscreteDataComp(metricId: string) {
    const MEANS: { [key: string]: number } = {
      KM_WB_Stride_Length_ht: 100.1,
    };

    const STD_DEVS: { [key: string]: number } = {
      KM_WB_Stride_Length_ht: 10.4,
    };

    const mean = MEANS[metricId] || 0;
    const stdDev = STD_DEVS[metricId] || 0;

    return {
      mean,
      stdDev,
      low: mean - stdDev,
      high: mean + stdDev,
    };
  }

  async getTrendsTimeSeriesMetrics(metricId: string) {
    const gradientStartColor = "#e400c0";
    const gradientEndColor = "#001fc3";
    const generateColor = (
      startColor: string,
      endColor: string,
      percentage: number
    ) => {
      const getColorComponent = (start: number, end: number) =>
        start + Math.round((end - start) * percentage);

      const r = getColorComponent(
        parseInt(startColor.slice(1, 3), 16),
        parseInt(endColor.slice(1, 3), 16)
      );
      const g = getColorComponent(
        parseInt(startColor.slice(3, 5), 16),
        parseInt(endColor.slice(3, 5), 16)
      );
      const b = getColorComponent(
        parseInt(startColor.slice(5, 7), 16),
        parseInt(endColor.slice(5, 7), 16)
      );

      return `#${r.toString(16).padStart(2, "0")}${g
        .toString(16)
        .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;
    };
    const filteredMetrics = TRENDS_TIME_SERIES_METRIC.filter(
      (metric) => metric.id === metricId
    );
    return filteredMetrics
      .sort((a: any, b: any) => a.date + b.date)
      .map((point, index) => {
        const color =
          filteredMetrics.length > 1
            ? generateColor(
                gradientStartColor,
                gradientEndColor,
                index / (filteredMetrics.length - 1)
              )
            : gradientStartColor;
        return {
          ...point,
          index,
          lineColor: color,
          averageRange: {
            from: -0.6704845000000006,
            to: 141.63000307366903,
          },
          min: -79.40388107366904,
          max: 205.19709407366904,
          color,
          marker: {
            enabled: false,
            symbol: "circle",
            radius: 4,
            states: {
              hover: {
                fillColor: "transparent",
                lineColor: color,
                lineWidth: 2,
              },
            },
          },
        };
      });
  }

  async fetchTrendsCorrelationData(
    {
      metricId,
      metricId2,
      playerId,
      trialType,
      startDate,
      endDate,
    }: FetchTrendsCorrelationDataArg,
    baseQuery: any
  ): Promise<{ data: Record<string, TrendsCorrelationMetric[]> }> {
    return baseQuery(
      `api/external/performance-dash/selfComp/discrete?pitcher_id=${playerId}&period=session&pitch_type=${trialType}&start_date=${startDate}&end_date=${endDate}&stat_type=mean&metric_name=${metricId}&metric_name=${metricId2}&limit=9999&trends=true`
    );
  }

  correlateMetricsData(
    firstMetricData: TrendsCorrelationMetric[],
    secondMetricData: TrendsCorrelationMetric[]
  ): {
    dataPoints: Dictionary<DataPoint>;
    commonValues: Dictionary<DataPoint>;
  } {
    const indexFirst = keyBy(firstMetricData, "date");
    const indexSecond = keyBy(secondMetricData, "date");

    const commonDates = intersection(
      Object.keys(indexFirst),
      Object.keys(indexSecond)
    );

    const commonValues: Dictionary<DataPoint> = fromPairs(
      commonDates.map((date) => [
        date,
        [indexFirst[date].mean, indexSecond[date].mean],
      ])
    );

    return {
      dataPoints: merge(
        // Default when there is no data for second metric
        mapValues(indexFirst, (data) => [data.mean, 0, data?.date]),
        // Default when there is no data for first metric
        mapValues(indexSecond, (data) => [0, data.mean, data?.date]),
        // Merged values, will overwrite the defaults
        commonValues
      ),
      commonValues,
    };
  }

  async getTrendsCorrelationData(
    options: FetchTrendsCorrelationDataArg,
    baseQuery: any
  ) {
    const { data } = await this.fetchTrendsCorrelationData(options, baseQuery);
    const { dataPoints, commonValues } = this.correlateMetricsData(
      data[options.metricId] ?? [],
      data[options.metricId2] ?? []
    );

    const mappedCommonValues = Object.keys(commonValues).map((date) => ({
      date,
      [options.metricId]: commonValues[date][0],
      [options.metricId2]: commonValues[date][1],
    }));

    return {
      dataPoints: chain(dataPoints)
        .values()
        .sortBy(([x]) => x)
        .value(),
      commonValues: mappedCommonValues,
    };
  }

  allMetricValues(playerMetrics: any) {
    const orderedPlayerMetrics = orderBy(
      playerMetrics,
      (metric) => metric?.created_at,
      ["desc"]
    );

    return orderedPlayerMetrics.map((current: any) => {
      const metricKeys = Object.keys(current).filter(
        (it) =>
          ![
            "_id",
            "date",
            "created_at",
            "updated_at",
            "player_id",
            "pwr_row_test_right",
          ].includes(it)
      );

      const metrics = {};

      metricKeys.forEach((metricKey: any) => {
        Object.assign(metrics, { [metricKey]: current[metricKey] });
      });

      return {
        id: current._id,
        playerId: current.player_id,
        date: dateToISO(current.date),
        metrics,
      };
    });
  }

  async adaptAllPlayerPerformanceData(
    rawPlayerMetrics: any,
    metricConventions: any
    //trendsData: any
  ) {
    const adaptedMetricConventions: MetricConvention[] =
      this.formatStringArraysToObject(metricConventions);

    const playerMetrics = !isEmpty(rawPlayerMetrics)
      ? rawPlayerMetrics
      : MOCK_PLAYER_PERFORMANCE_DATA;

    const allMetricValues = this.allMetricValues(playerMetrics);

    const data: any = allMetricValues.map((current) => ({
      ...current,
      metrics: this.groupPlayerPerformanceDataForPeriod(current.metrics),
    }));

    const getAggregatesByMetricKey = (key: string) => {
      const values = playerMetrics
        .map((metrics: any) => Number(metrics[key]))
        .filter((it: number) => !isNaN(it));

      return {
        average: mean(values),
        max: max(values),
        min: min(values),
      };
    };

    return data.map((current: any) => {
      const events = current.metrics;
      const eventsKeys = Object.keys(events);

      return {
        ...current,
        metrics: eventsKeys.map((eventKey) => ({
          key: eventKey,
          label: getPlayerMetricEasyName(eventKey),
          metrics: Object.keys(events[eventKey]).map((metricKey) => {
            const currentMetric = this.findMetricByVariableName(
              metricKey,
              adaptedMetricConventions
            );
            const aggregates = getAggregatesByMetricKey(metricKey);
            const decimals = currentMetric?.decimals || 0;
            const value = events[eventKey][metricKey];

            return {
              metric: metricKey,
              label: currentMetric?.Name,
              value: parseMetricValue(value, decimals),
              history: parseMetricValue(value, decimals),
              average: aggregates.average,
              max: aggregates.max,
              min: aggregates.min,
              yBounds: {
                max: aggregates.max,
                min: aggregates.min,
              },
              unit: this.getMetricConventionUnit(
                currentMetric?.metric_units || ""
              ),
              decimals: currentMetric?.decimals,
            };
          }),
        })),
      };
    });
  }

  toDecimalsOrDefault(value: number, decimals: number | undefined) {
    return !isNil(decimals)
      ? value
      : !isNil(value)
      ? Number(Number(value).toFixed(decimals))
      : value;
  }

  makeCategoryKey(key: string) {
    const metricsKeysFormats = {
      "MSK Strength": "MSK",
      ROM: "ROM",
      Power: "Power",
      Speed: "Speed",
      "Body Composition": "BodyComp",
      Vision: "Vision",
      Scores: "Scores",
      Workload: "Workload",
    };

    return get(metricsKeysFormats, key, key);
  }

  formatMetricOfLogicData(metric: any) {
    const cleanLines = (it: any) => {
      const isReplaceable = !isNil(it) && typeof it === "string";

      return isReplaceable ? it?.replace("\r", "") : it;
    };

    const THRESHOLDS = [
      "position_threshold1",
      "position_threshold2",
      "pitcher_threshold1",
      "pitcher_threshold2",
    ];

    const getThresholds = (playerPosition: "pitcher" | "position") => [
      Number(metric?.[`${playerPosition}_threshold1`]),
      Number(metric?.[`${playerPosition}_threshold2`]),
    ];

    return mapValues(
      {
        ...omit(metric, THRESHOLDS),
        label: metric.variable,
        key: metric.variabledb,
        thresholds: {
          Pitcher: getThresholds("pitcher"),
          "Position Player": getThresholds("position"),
        },
      },
      cleanLines
    );
  }

  buildTeamVariableGroups = (teamMetricsConventionCSV: any[][]) => {
    const keys: any[] = Object.values(teamMetricsConventionCSV[0]);

    return teamMetricsConventionCSV.splice(1).map((it: any[]) => {
      let current = {};
      it.forEach((element: any, index: number) => {
        Object.assign(current, { [trim(keys[index])]: element });
      });

      return current;
    });
  };

  playerDominantSide(player: any) {
    return player.throws === "L" ? "left" : "right";
  }

  async getMetricInfo(
    metricId: string,
    player: any
  ): Promise<ExpandedMetricInfo> {
    const oppositeSide = getOppositeSide(player.dominantSide) || "left";
    const handenessMetricId =
      sidelessMetric(metricId) === metricId
        ? metricId
        : metricId
            .replace(player.dominantSide, "dom")
            .replace(oppositeSide, "nondom");
    const image = `/player/images/${this.transformToDom(
      handenessMetricId
    )}.jpg`;
    return (
      EXPANDED_METRICS_INFO.filter(
        (metric: any) =>
          metric.metricId === handenessMetricId || metric.metricId === metricId
      ).map((metric) => {
        return {
          ...metric,
          image,
        };
      })[0] || { image, metricId }
    );
  }

  transformToDom(handenessMetricId: string) {
    return handenessMetricId.replace("_nondom", "_dom");
  }

  async getCorrelationInfo(metricId: string, player: any) {
    const handenessMetricId = this.handenessMetricId(metricId, player);
    return EXPANDED_METRICS_INFO.filter(
      (metric: any) =>
        metric.metricId === handenessMetricId || metric.metricId === metricId
    ).map((metric) => {
      return {
        ...metric,
        info: metric.correlationInfo,
        image: `/player/images/correlations/${handenessMetricId}.png`,
      };
    })[0];
  }

  handenessMetricId(metricId: string, player: any) {
    return sidelessMetric(metricId) === metricId
      ? metricId
      : metricId
          .replace(player.dominantSide, "dom")
          .replace(getOppositeSide(player.dominantSide) || "left", "nondom");
  }

  async getNormativeData(metricId: string, player: any) {
    const handenessMetricId = this.handenessMetricId(metricId, player);
    const teamNormativeRanges = await this.downloadTeamNormativeRanges();
    const playerPositionGroup = isPlayerPitcher(player)
      ? "Pitcher"
      : "Position Player";
    const formatValue = (value: any) => truncToDecimalPlaces(Number(value), 1);
    const positions = ["Catcher", "Infield", "Outfield", "Pitcher"];
    const metricNormativeRanges: {
      level: string;
      mean: number;
      stdDev: number;
    }[] = teamNormativeRanges
      .filter(
        (it: any) =>
          it.metricName === metricId &&
          (it.positionGroup === playerPositionGroup ||
            (it.levelName === "Position" &&
              positions.includes(it.positionGroup)))
      )
      .map((it: any) => {
        return {
          level: it.levelName === "Position" ? it.positionGroup : it.levelName,
          mean: formatValue(it.average),
          stdDev: formatValue(it.stdev),
        };
      });
    const levelOrder = [
      "MLB",
      "AAA",
      "AA",
      "High A",
      "Low A",
      "FCL",
      "DSL",
      ...positions,
    ];
    const data = chain(metricNormativeRanges)
      .map((metric) => ({
        ...metric,
        "-1stdDev": truncToDecimalPlaces(
          Number(metric?.mean) - Number(metric?.stdDev),
          1
        ),
        "-2stdDev": truncToDecimalPlaces(
          Number(metric?.mean) - 2 * Number(metric?.stdDev),
          1
        ),
        "1stdDev": truncToDecimalPlaces(
          Number(metric?.mean) + Number(metric?.stdDev),
          1
        ),
        "2stdDev": truncToDecimalPlaces(
          Number(metric?.mean) + 2 * Number(metric?.stdDev),
          1
        ),
      }))
      .sortBy((it) => levelOrder.indexOf(it.level))
      .value();
    const averageByField = (field: string) => formatValue(meanBy(data, field));
    const dataWithOrganizationInfo = [
      {
        level: "Organization",
        mean: averageByField("mean"),
        stdDev: averageByField("stdDev"),
        "-1stdDev": averageByField("-1stdDev"),
        "-2stdDev": averageByField("-2stdDev"),
        "1stdDev": averageByField("1stdDev"),
        "2stdDev": averageByField("2stdDev"),
      },
    ].concat(data);
    return { metricId: handenessMetricId, data: dataWithOrganizationInfo };
  }

  adaptDataEntries(rawDataEntries: any, players: any[]) {
    return rawDataEntries
      .map((it: any) => {
        const player = players?.find((player) => player.id === it.player_id);
        return this.adaptDataEntryDetail(it, { player });
      })
      .filter((it: any) => it);
  }

  adaptDataEntryDetail(dataEntryForm: any, rawDataEntryDetail: any = {}) {
    return {
      id: dataEntryForm.data_entry_id || rawDataEntryDetail.data_entry_id,
      details: {
        assessmentType:
          dataEntryForm.assessment_type || rawDataEntryDetail.assessment_type,
        date:
          dataEntryForm.created_at ||
          rawDataEntryDetail.created_at ||
          dataEntryForm.metric_date ||
          rawDataEntryDetail.metric_date,
        player: {
          value:
            rawDataEntryDetail.player?.metsId ||
            rawDataEntryDetail.player?.id ||
            dataEntryForm.player_id ||
            rawDataEntryDetail.player_id,
          displayValue:
            rawDataEntryDetail.player?.displayName ||
            rawDataEntryDetail.player?.name ||
            rawDataEntryDetail.player_name,
          position:
            rawDataEntryDetail.player?.position ||
            rawDataEntryDetail.player_position,
        },
        enteredBy: dataEntryForm.entered_by || rawDataEntryDetail?.entered_by,
      },
      data: fromPairs(
        (dataEntryForm?.metrics || [])
          .concat(rawDataEntryDetail?.body?.metrics || [])
          .concat(rawDataEntryDetail?.metrics || [])
          ?.map((metric: any) => [metric.name, metric.value]) || {}
      ),
    };
  }

  async searchPlayerByMetsId(baseQuery: any, playerId: string) {
    return await baseQuery(
      `api/search?queryString=${playerId}&searchTypes=players`
    );
  }

  formatStringArraysToObject = (data: any[][], toCamel = false): any[] => {
    const headerKeys = data[0];

    return data.slice(1).map((row: any[]) => {
      const object = {};

      headerKeys.forEach((key: string, index) => {
        Object.assign(object, { [toCamel ? camelCase(key) : key]: row[index] });
      });

      return object;
    });
  };

  getStandardDeviation(array: number[]) {
    const n = array.length;
    const mean = array.reduce((a, b) => a + b) / n;
    return Math.sqrt(
      array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
    );
  }

  async buildPriorities(
    player: any,
    playerMetrics: any,
    timePeriod: any
  ): Promise<PlayerPriorityCategory[]> {
    //transfer metric keys look like `perf_[type]_priority_[number]_[field]`
    const validMetricObjects = chain(
      this.filterMetricsInPeriod(playerMetrics, timePeriod)
    )
      .filter((it) => {
        const hasInsightMetrics = some(Object.keys(it), isPlayerPrioritiesKey);
        return hasInsightMetrics;
      })
      .value();
    //Find all available types (strength, med, nutrition, etc)
    const types = chain(validMetricObjects)
      .flatMap((metricObject) =>
        Object.keys(metricObject)
          .filter(isPlayerPrioritiesKey)
          .map((metricKey) => playerPrioritiesMetricToType(metricKey))
      )
      .uniq()
      .value();
    //Build priorities por each type
    const prioritiesPromises = types.map(async (type) => {
      const typePrefix = `perf_${type}`;
      const validMetricObjectsForType = validMetricObjects.filter(
        (metricObject) =>
          metricObject.date &&
          some(Object.keys(metricObject), (key) => startsWith(key, typePrefix))
      );
      const mostRecentRecordForType = maxBy(
        validMetricObjectsForType,
        (it) => new Date(it.date)
      );
      return promiseProps({
        type,
        date: mostRecentRecordForType.date,
        color: mostRecentRecordForType[`${typePrefix}_priority_color`],
        priorities: Promise.all(
          ["one", "two", "three", "four"].map(async (priorityLevel, i) => {
            const mostRecentRecordForTypeAndLevel = maxBy(
              validMetricObjectsForType.filter(
                (it) =>
                  it[`${typePrefix}_priority_${priorityLevel}_intent`] ||
                  it[`${typePrefix}_priority_${priorityLevel}_objective`] ||
                  it[`${typePrefix}_priority_${priorityLevel}_notes`]
              ),
              (it) => new Date(it.date)
            );
            if (!mostRecentRecordForTypeAndLevel) return {};
            const findMostRecentLevelValue = (keySuffix: string) => {
              return mostRecentRecordForTypeAndLevel[
                `${typePrefix}_priority_${priorityLevel}_${keySuffix}`
              ];
            };
            const objective = findMostRecentLevelValue("objective");
            const objectiveLabel = await metricKeyToLabel(objective, objective);
            return {
              priorityLevel: i + 1,
              intent: findMostRecentLevelValue("intent"),
              objective,
              objectiveLabel: removeSideIndicator(objectiveLabel),
              dominanceLabel: dominanceLabel(
                objectiveLabel,
                playerHandedness(player)
              ),
              notes: findMostRecentLevelValue("notes"),
            };
          })
        ).then(
          (it) => it.filter((priority) => priority.intent && priority.objective) //Keep only complete priorities
        ),
      });
    });
    const priorities = await Promise.all(prioritiesPromises);
    //Keep only types with priorities
    const completeTypes = priorities.filter((it) => !isEmpty(it.priorities));
    return completeTypes;
  }

  buildTemperatureData(
    playerMetrics: any,
    timePeriod: Omit<Period, "label">
  ): Dictionary<Temperature> {
    return chain(
      this.selectValidMetrics(
        playerMetrics,
        timePeriod,
        (key) => key === "temperature"
      )
    )
      .map((it) => [it.date, it.temperature])
      .fromPairs()
      .value();
  }

  buildTransfers(playerMetrics: any, timePeriod: any) {
    //transfer metric keys look like `transfer_[type]_[field]`
    return this.buildChipDataFromMetrics(
      playerMetrics,
      timePeriod,
      isPlayerTransfersKey,
      playerTransferMetricToType
    );
  }
  buildInsights(playerMetrics: any, timePeriod: any) {
    //insights metric keys look like `player_insights_[type]_[field]`
    return this.buildChipDataFromMetrics(
      playerMetrics,
      timePeriod,
      isPlayerInsightsKey,
      playerInsightsMetricToType
    );
  }
  buildNutritionInsights(playerMetrics: any, timePeriod: any) {
    //nutrition insights metric keys look like `nutrition_[field]`
    return this.buildChipDataFromMetrics(
      playerMetrics,
      timePeriod,
      isNutritionInsightsKey,
      playerNutritionInsightsMetricToType,
      (it) => pick(it, ["nutrition_supplements"])
    );
  }
  buildOffSeasonContactInsights(playerMetrics: any, timePeriod: any) {
    //off season contact insights metric keys look like `oscontact_[medical|sc]_[field]`
    const medicalNotes = this.buildChipDataFromMetrics(
      playerMetrics,
      timePeriod,
      isOffSeasonContactMedicalInsightsKey,
      playerOffSeasonContactInsightsMetricToType,
      (it) => pick(it, ["oscontact_player_status"])
    ).map((it) => ({ ...it, text: `Medical Notes: ${it.text}` }));
    const scNotes = this.buildChipDataFromMetrics(
      playerMetrics,
      timePeriod,
      isOffSeasonContactSCInsightsKey,
      playerOffSeasonContactInsightsMetricToType
    ).map((it) => ({ ...it, text: `S&C Notes: ${it.text}` }));
    return medicalNotes.concat(scNotes);
  }

  buildChipDataFromMetrics(
    playerMetrics: any,
    timePeriod: any,
    isValidKey: (key: string) => boolean,
    typeGetter: (key: string) => string,
    extraFieldsBuilder?: (metrics: string) => any
  ) {
    return chain(this.selectValidMetrics(playerMetrics, timePeriod, isValidKey))
      .groupBy("date")
      .flatMap((metricsByDay: any, date: string) => {
        const metrics = metricsByDay[0];
        const playerChipTextKeys = Object.keys(metrics)
          .filter(isValidKey)
          .filter((key) => endsWith(key, "_text") || endsWith(key, "_notes"));

        return playerChipTextKeys.map((textKey) => {
          const type = typeGetter(textKey);
          const text = metrics[textKey];
          const enteredBy =
            metrics[
              textKey
                .replace("_text", "_enteredby")
                .replace("_notes", "_enteredby")
            ];
          const extraFields = extraFieldsBuilder
            ? extraFieldsBuilder(metrics)
            : null;
          return { date, type, text, enteredBy, ...extraFields };
        });
      })
      .orderBy("date", ["desc"])
      .value();
  }

  selectValidMetrics(
    playerMetrics: any,
    timePeriod: any,
    isValidKey: (key: string) => boolean
  ) {
    return this.filterMetricsInPeriod(playerMetrics, timePeriod).filter(
      (it: any) => some(Object.keys(it), isValidKey)
    );
  }

  async adaptPlayerTrends(trendsData: any) {
    const allPlayerTrends = await Promise.all(
      map(trendsData, (apiValue, metricKey) => {
        const { value, date } = isString(apiValue)
          ? { value: apiValue, date: new Date() }
          : apiValue;
        const type = metricKey.split("_")[0].toUpperCase();
        return promiseProps({
          id: `${metricKey}-${date}`,
          date: new Date(dateToISO(date)),
          type,
          measurement: metricKeyToLabel(metricKey),
          trend: mapTrend(value),
          key: metricKey,
        });
      })
    );

    const playerTrends = allPlayerTrends.filter(
      (it: PlayerTrends) =>
        //Show only translated measurements where trend is not stable
        !isNil(it.measurement) &&
        it.trend != "stable" &&
        !startsWith(it.id, "gps_")
    );
    return playerTrends;
  }
  async fetchSelfData({
    rawResponse,
    selfCompMode = "discrete",
    playerId,
    metricId,
    selfCompOptions,
    baseQuery,
  }: FetchSelfDataProps) {
    const rawData = await fetchSelfCompForMetric(
      {
        playerId,
        selfCompMode,
        startDate: dayjs(selfCompOptions.payload?.date).format("YYYY-MM-DD"),
        endDate: dayjs(selfCompOptions.payload?.endDate).format("YYYY-MM-DD"),
        period: selfCompOptions.type.toLowerCase(),
        statType: "mean",
        pitchType: selfCompOptions.payload?.pitchType,
        sup75Perc: selfCompOptions.payload?.sup75Perc,
        metricName: metricId,
      },
      baseQuery
    );

    if (rawResponse) {
      return rawData;
    }

    const selfCompData = flatMap(rawData, (selfCompObject: any) => {
      const nonMetricNameKeys = ["sup_75perc", "pitch_type"];
      const metricNames = Object.keys(selfCompObject).filter(
        (key) => !nonMetricNameKeys.includes(key)
      );
      return metricNames.map((metricName) => ({
        value: this.changeValueUnitIfNeeded(
          { label: metricName },
          selfCompObject[metricName]
        ),
        systematicName: metricName,
        id: metricName,
        key: metricName,
      }));
    });

    return selfCompData;
  }
  async downloadTeamNormativeRanges() {
    return this.downloadCSVAsObject("player/data/team_normative_ranges.csv");
  }

  async downloadCSVAsObject(url: string) {
    return adapterCache.withCache(url, async () => {
      const csv = await downloadParsedCSV(url);
      return this.formatStringArraysToObject(csv, true);
    });
  }

  adapt3dBattingMetric(metric: string, event: string) {
    return metric.endsWith("_") ? `${metric}TS_${event}` : metric;
  }

  adaptBatDynamicsDiscreteData(
    discreteMetrics: Dictionary<unknown>,
    discreteDataComp: DiscreteDataComp[],
    rawMetricsConventions: string[][],
    discreteMetricsLabels: any[]
  ) {
    const BASE_METRICS = [
      "KM_G_Bat_SSVelocity_Max",
      "KM_G_Bat_ImpactDeltaTip_Mag",
      "KM_G_Hands_Velocity_Max",
      "KM_G_BatKnob_Accel_Z_TS_MIN",
      "KM_G_Bat_Path_DStoBC",
      "KM_G_Bat_Angle_VertAttack_",
      "KM_G_Bat_Angle_HorzAttack_",
      "KM_G_Bat_SwingTime_Mag",
      "KM_G_Bat_OnPlane_Perc",
      "KM_G_Bat_Angle_Vert_",
      "KM_G_Bat_Angle_Horz_",
      "POS_G_BatSS_ImpactLoc_Mag",
      "POS_G_BatTip_ImpactLoc_Z",
      "POS_G_BatTip_ImpactLoc_Y",
      "POS_G_Ball_Depth_Mag",
      "POS_G_Ball_Height_Mag",
      "POS_G_Ball_Width_Mag",
      "KM_G_FairContact_Time",
    ];
    const metricsConventions = this.formatMetricsConvention(
      rawMetricsConventions,
      discreteMetricsLabels
    );
    return BASE_METRICS.map((metric) => {
      const adaptedMetric = this.adapt3dBattingMetric(metric, "BC");
      const currentConvention = metricsConventions?.find(
        (it) => it?.systematicName === metric
      ) || {
        type: "",
        bodyPart: "",
        easyName: "",
        systematicName: "",
        unit: "",
        decimals: 0,
      };
      const currentDataComp = discreteDataComp?.find(
        (it) => it?.id === adaptedMetric
      ) || {
        id: adaptedMetric,
        mean: 0,
        stdev: 0,
        min: 0,
        max: 0,
        averageRange: {
          from: 0,
          to: 0,
        },
      };
      const decimalsNumber = +currentConvention.decimals;

      currentDataComp.averageRange.to = truncToDecimalPlaces(
        currentDataComp.averageRange.to,
        decimalsNumber
      );
      currentDataComp.averageRange.from = truncToDecimalPlaces(
        currentDataComp.averageRange.from,
        decimalsNumber
      );
      currentDataComp.mean = truncToDecimalPlaces(
        currentDataComp.mean,
        decimalsNumber
      );
      currentDataComp.min = truncToDecimalPlaces(
        currentDataComp.min,
        decimalsNumber
      );
      currentDataComp.max = truncToDecimalPlaces(
        currentDataComp.max,
        decimalsNumber
      );
      currentDataComp.stdev = truncToDecimalPlaces(
        currentDataComp.stdev,
        decimalsNumber
      );
      const currentDiscreteValue = Number(discreteMetrics?.[adaptedMetric]);
      return {
        ...currentDataComp,
        value: !isNaN(currentDiscreteValue)
          ? this.changeValueUnitIfNeeded(
              { label: metric },
              currentDiscreteValue
            )
          : undefined,
        label: currentConvention?.easyName,
        unit: currentConvention?.unit,
        decimals: currentConvention?.decimals,
      };
    });
  }

  adaptTimeSeriesDataIfNeeded(data: any[], key: string, isDataComp?: boolean) {
    return data.map((it) => {
      const dataCompArgs = isDataComp
        ? { dataComp: { from: it.low, to: it.high } }
        : {};
      const mappedMetric = mapMetricFieldsIfNeeded({
        key,
        value: it,
        ...dataCompArgs,
      });

      if (isDataComp) {
        return {
          x: it.x,
          low: mappedMetric?.dataComp?.from,
          high: mappedMetric?.dataComp?.to,
        };
      }

      return !isNil(mappedMetric?.value) ? mappedMetric?.value : it;
    });
  }
}

function regularOrAvgEndpointPath(
  id: string,
  motionType: MotionType,
  pitch: Pitch,
  dataType: string
) {
  return trialIsForAvgSkele(id)
    ? avgFileUrl(id.toString(), dataType)
    : `${motionType === MotionType.Pitching ? "pitches" : "swings"}/${
        pitch.id
      }/${dataType}`;
}

export function trialIsForAvgSkele(id: string) {
  return id.includes(AVG_SKELE_MOTION_FILE_NAME);
}

export function isPlayerPitcher(player: any) {
  return ["P", "SP", "RP", "TWP"].includes(player?.primaryPosition || "");
}

export function getPlayerPositionByDataComp(selectedDataComp: string) {
  return selectedDataComp === DATACOMP_VALUES[0].key
    ? "Pitcher"
    : "Position Player";
}

export function dateToISO(day: string) {
  const date = dayjs(day, "YYYY-MM-DD");

  if (!date.isValid()) {
    return "";
  }

  //If we use the day as is, it displays it as the day before. Add some hours to avoid timezone mismatch.
  return date.add(6, "hour").toISOString();
}
export function isPlayerInsightsKey(key: string) {
  return startsWith(key, "player_insights_");
}
export function isNutritionInsightsKey(key: string) {
  return startsWith(key, "nutrition_");
}
export function isOffSeasonContactMedicalInsightsKey(key: string) {
  return startsWith(key, "oscontact_medical_");
}
export function isOffSeasonContactSCInsightsKey(key: string) {
  return startsWith(key, "oscontact_sc_");
}
function isPlayerTransfersKey(key: string) {
  return startsWith(key, "transfer_");
}
function isPlayerPrioritiesKey(key: string) {
  return (
    startsWith(key, "perf_") &&
    //There are some random metrics that start with perf that shouldn't be there, like "perf_strength_priority_color"
    (key.includes("one") ||
      key.includes("two") ||
      key.includes("three") ||
      key.includes("four"))
  );
}

function playerTransferMetricToType(it: string) {
  const rawType = it.split("_")[1];
  return (
    { med: "MED", strength: "S&C", nutrition: "NUTRI" }[rawType] || rawType
  );
}
function playerInsightsMetricToType(it: string) {
  return it.split("_")[2];
}
/* eslint-disable-next-line no-unused-vars */
function playerNutritionInsightsMetricToType(it: string) {
  return "NUTR";
}

/* eslint-disable-next-line no-unused-vars */
function playerOffSeasonContactInsightsMetricToType(it: string) {
  return "OFF";
}
export function playerPrioritiesMetricToType(it: string) {
  return it.split("_")[1];
}

export function positionGroup(position: string) {
  const positions: any = {
    P: "pitcher",
    RP: "pitcher",
    SP: "pitcher",
    TWP: "pitcher",
    C: "catcher",
    "1B": "infield",
    "2B": "infield",
    "3B": "infield",
    SS: "infield",
    RF: "outfield",
    CF: "outfield",
    LF: "outfield",
    DH: "utility",
    other: "utility",
  };
  return positions[position] || "utility";
}

export async function downloadMetricLabelsCSV(motionType: MotionType) {
  return adapterCache.withCache(
    `time-series-labels-${motionType}`,
    async () => {
      return await downloadParsedCSV(
        motionType === MotionType.Batting
          ? "3d/data/timeSeriesLabelsHitting.csv"
          : "3d/data/timeSeriesLabels.csv"
      );
    }
  );
}

export async function download3DMetricsConventionsCSV(motionType: MotionType) {
  const suffix = motionType === MotionType.Pitching ? "" : "Hitting";
  return adapterCache.withCache(`3d-metrics-conventions-${motionType}`, () =>
    downloadParsedCSV(`3d/data/metricsConvention${suffix}.csv`)
  );
}

export function avgFileUrl(id: string, fileKey: string) {
  const fileToDownload = {
    raw: id,
    motion: id,
    discrete: id.replace(AVG_SKELE_MOTION_FILE_NAME, "cbma_discrete_AVG.csv"),
    timeSeries: id.replace(
      AVG_SKELE_MOTION_FILE_NAME,
      "cbma_time_series_AVG.csv"
    ),
  }[fileKey];
  return `avgSkele/files/${encodeURIComponent(fileToDownload || id)}`;
}
