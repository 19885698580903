import React, { useEffect, useMemo, useState } from "react";
import { Select, Table } from "antd";
import dayjs from "dayjs";
import { PlayerSession, SourcePitch } from "../../services/mockData";
import PaginationTable from "../PlayerDashboard/PlayerInfo/PaginationTable";
import { SourceDot } from "./SourceDot";
import { isEmpty } from "lodash";
import { Paper, TableContainer } from "@mui/material";
const { Column } = Table;

export function SessionPickerTable({
  playerId,
  rawSessions,
  totalItems,
  pageSize,
  isFetching,
  noSelect,
  onChange,
  currentPage,
  setCurrentPage,
  forceLoading,
  calculatePagesLocally,
  noContainerComponent,
  noSource,
  alwaysShowPagination,
}: {
  playerId?: string;
  rawSessions: PlayerSession[];
  totalItems: number;
  pageSize: number;
  isFetching?: boolean;
  forceLoading?: boolean;
  onChange: (value: PlayerSession) => void;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  currentPage: number;
  noSelect?: boolean;
  calculatePagesLocally?: boolean;
  noContainerComponent?: boolean;
  noSource?: boolean;
  alwaysShowPagination?: boolean;
}) {
  const [selectedSession, setSelectedSession] = useState<PlayerSession | null>(
    null
  );
  const currentItemsCount = !totalItems ? 0 : currentPage * pageSize + 1;
  const totalItemsCount = Math.min((currentPage + 1) * pageSize, totalItems);

  const allSessions = playerId ? rawSessions || [] : [];
  const sessions = useMemo(() => {
    if (!calculatePagesLocally) return allSessions;
    return allSessions.slice(
      currentPage * pageSize,
      (currentPage + 1) * pageSize
    );
  }, [allSessions, currentPage]);
  const paginationParams = {
    currentPage,
    goToNextPage: () => setCurrentPage(currentPage + 1),
    goToPrevPage: () => setCurrentPage(currentPage - 1),
    currentItemsCount,
    totalItemsCount,
    totalItems,
    totalPages: totalItems / pageSize,
    pageSize,
  };
  useEffect(() => {
    if (!noSelect && !selectedSession && !isEmpty(sessions)) {
      setSelectedSession(sessions[0]);
    }
  }, [sessions]);
  return (
    <>
      {!noSelect && (
        <Select
          open={false}
          style={{ width: "100%" }}
          placeholder={`Chosen session: ${selectedSession?.date || ""}`}
        />
      )}

      <TableContainer
        {...(!noContainerComponent && { component: Paper })}
        sx={{ p: 1 }}
      >
        <Table
          loading={forceLoading || isFetching}
          size="small"
          dataSource={sessions || []}
          rowKey={"date"}
          pagination={false}
          rowSelection={
            isEmpty(sessions)
              ? undefined
              : {
                  onSelect: (session: any) => {
                    setSelectedSession(session);
                    onChange(session);
                  },
                  type: "radio",
                  selectedRowKeys: selectedSession
                    ? [selectedSession.date]
                    : [],
                }
          }
        >
          <Column
            title="Date"
            dataIndex={"date"}
            align="center"
            render={(value) => {
              return dayjs(value).format("M/D/YYYY");
            }}
          />
          <Column
            title="Session Type"
            align="center"
            render={(session: PlayerSession) => {
              const sessionType =
                session.sources.includes(SourcePitch.LabKinatrax) ||
                session.sources.includes(SourcePitch.KinatraxNG)
                  ? "Side"
                  : "Game";

              return (
                <>
                  {sessionType} ({session.count}p)
                </>
              );
            }}
          />
          {!noSource && (
            <Column
              title="Sources"
              align="center"
              dataIndex={"sources"}
              render={(sources: SourcePitch[]) => {
                return sources?.map((source) => (
                  <SourceDot key={source} source={source} />
                ));
              }}
            />
          )}
        </Table>

        {(!isEmpty(sessions) || alwaysShowPagination) && (
          <PaginationTable {...paginationParams} zeroBasedIndex />
        )}
      </TableContainer>
    </>
  );
}
