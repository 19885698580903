import {
  flatten,
  isEmpty,
  isNil,
  padStart,
  round,
  startsWith,
  subtract,
  sum,
  omit,
  sortBy,
  reverse,
  isFinite,
  isNumber,
  last,
  indexOf,
  uniq,
  castArray,
  findIndex,
} from "lodash";
import {
  BATTING_BODY_PARTS_EVENTS,
  Pitch,
  PITCHING_BODY_PARTS_EVENTS,
  Player,
} from "../services/mockData";
import { Metric, Section } from "../services/mockMetrics";
import dayjs from "dayjs";
import { ExpandedMetricsDataIndexing } from "../components/PlayerDashboard/ExpandedMetricsDataIndexing";
import { PITCHER_TYPES } from "../hooks/pitcherTypes";
import { isStringNumber } from "./strings";
import { spineMobilityProp } from "../components/TeamDashboard/TeamTable/spineMobilityHelpers";
import { getHydrationLabel } from "../components/common/hydrationColors";
import { Result } from "regression";
import { MotionType } from "../components/common/MotionType";
import { SelfCompSelectedValue } from "../components/common/SelfMenu";
import { DiscreteDataItem } from "../components/InteractiveReportsTool/interactiveReportApi.service";

export const labelToId = (label: string, array: any[]) =>
  array.filter((tab) => tab.label === label)[0]?.id;

export const mapMenuItems = (items: Metric[]) =>
  items.map((item) => ({
    label: item.label,
    key: item.id,
  }));

export const toMenuItems = (metrics: any[], section: Section) =>
  mapMenuItems(metrics.filter((metric) => metric.section === section));

export const replace = (array: any[], index: number, value: any) =>
  array.map((element, i) => (i === index ? value : element));

export const toMetric = (data: any) => ({ visible: true, ...data });
export const metricsLimit = 2;
export const metricColors = [
  "borderBlue",
  "hoveredDigitalPink",
  "ptd-green",
  "ptd-red",
  "ptd-dashboard-yellow",
];

export const basicFrame = (frame: number) => ({ label: "", frame });

export const toPlotLineMetrics = (
  metrics: any[]
): { label: any; value: number }[] =>
  metrics.map((metric) => ({
    label: {
      align: "right",
      verticalAlign: "bottom",
      y: -10,
      style: { color: "#94b9ea" },
      text: metric.label,
    },
    value: metric.frame,
  }));

export const frameToMilliFromBrOffset = (
  frame: number,
  brFrame: number,
  duration: number
) => (frame - brFrame) * duration;

export const calculateValuesBy = (metric: string, data: any[]) => {
  const baseValue = data.find(({ label: { text } }) => text === metric)?.value;

  if (!baseValue) {
    return data;
  }

  return data.map((it) => ({ ...it, value: it.value - baseValue }));
};

type Offset = {
  top?: number;
  bottom?: number;
};

export const getBounds = (data: number[], offset?: Offset) => ({
  min: Math.min(...data) + (offset?.bottom || 0) * -1,
  max: Math.max(...data) + (offset?.top || 0),
});

export const isSecondary = (metric: any) =>
  metric?.label?.includes("Secondary");

export const notIsSecondary = (metric: any) => !isSecondary(metric);

export const addIndex = (metrics: any[]) =>
  metrics.map((metric, index) => ({ ...metric, index }));

export const addTrialIndex = (data: any[], trialIndex: number) =>
  data.map((it: any) => ({ ...it, trialIndex }));

export const findOrDefaultMockDataByPlayer = (player: Player, data: any[]) => {
  const playerId = data.find(({ playerId }) => player.id === playerId) || 1;

  return data.filter((pitch) => playerId === pitch.playerId);
};

export const fixMetricId = (metricId: string): string => {
  const metricsDictionary = {
    mKHShort: "MKH",
    handSeparation: "HS",
    initialFootContact: "IFC",
    firmFootContact: "MER",
    release: "BR",
    followThrough: "FT",
  };

  // @ts-expect-error
  return metricsDictionary[metricId];
};

export const filterByCurrentMetrics = (data: any[] = [], metrics: any[] = []) =>
  metrics
    .filter((metric) => metric.visible)
    .map((metric) => data.find((it) => it?.id === metric?.id))
    .filter((it) => !isEmpty(it));

export const getSeriesValues = (data: any[]) =>
  data?.map((aSeries) => aSeries?.data?.map((it: number[]) => it[0]));

export const getAllSeriesValues = (
  primaryData: any[],
  secondaryData: any[]
) => {
  let data = primaryData;

  if (!isEmpty(secondaryData)) {
    const fixedData = secondaryData.map((aSeries: any) => ({
      ...aSeries,
      data: aSeries.data.map((it: any) => {
        const isArearange = !isNil(it?.high);

        return isArearange ? [it.x] : it;
      }),
    }));

    data = data.concat(fixedData);
  }

  return flatten(getSeriesValues(data));
};

export const removeSpecificMetrics = (
  data: any[],
  metrics_to_remove: string[],
  UNITS_TO_REMOVE: string[]
) => {
  return data.map((item) => ({
    ...item,
    children: item.children?.map((child: any) => ({
      ...child,
      children: child.children?.filter(
        (metric: any) =>
          !metrics_to_remove.some(
            (label) =>
              metric?.label.includes(label) ||
              UNITS_TO_REMOVE.includes(metric?.unit)
          )
      ),
    })),
  }));
};

export const removeEmptyMetrics = (data: any) => {
  return data
    ?.map((node: any) => {
      if (node.children) {
        const children = removeEmptyMetrics(node.children);
        if (children.length === 0) {
          return undefined;
        }
        return { ...node, children };
      }
      return node;
    })
    ?.filter(Boolean);
};

export function trialNumberText(pitch: Pitch, motionType: MotionType) {
  const paddedIndex = padStart(`${(pitch?.index || 0) + 1}`, 2, "0");
  return `${motionType === MotionType.Batting ? "S" : "P"} ${paddedIndex}`;
}
function getShortOutcome(outcome?: string) {
  const outcomeMap: any = {
    swing_miss: "S+M",
    take: "T",
    foul: "F",
    strikeout: "SO",
    walk: "BB",
    groundball: "GB",
    popout: "PO",
    pop_out: "PO",
    flyout: "FO",
    field_out: "FO",
    single: "BH",
    base_hit: "BH",
    double: "2BH",
    triple: "3BH",
    homerun: "HR",
    home_run: "HR",
  };
  return outcomeMap[outcome || ""] || outcome;
}

function swingDescriptionText(trial: Pitch) {
  const atBatNumber = trial.pa_of_batter || "-";
  const swingNumber = trial.pitch_of_pa || "-";
  const outcome = getShortOutcome(trial.pitch_result || trial.outcome);
  const exitVelocity = trial.hitSpeed ? `${trial.hitSpeed.toFixed(1)}` : "-";
  const launchAngle = trial.launchAngle
    ? `${trial.launchAngle.toFixed(1)}`
    : "-";
  const pitchType = trial.type;
  return `${trialNumberText(
    trial,
    MotionType.Batting
  )} ${atBatNumber}.${swingNumber} (${outcome}) ${exitVelocity} MPH, ${launchAngle}° (${pitchType})`;
}

export function trialDescriptionText(trial: Pitch, motionType: MotionType) {
  if (motionType === MotionType.Batting) {
    return swingDescriptionText(trial);
  }
  return pitchDescriptionText(trial);
}

export function pitchDescriptionText(pitch: Pitch) {
  const batterHandedness = pitch?.batter_side ? `${pitch?.batter_side}HH` : "";

  return `${trialNumberText(pitch, MotionType.Pitching)} (${
    pitch?.inning ||
    pitch?.strikesOrOuts ||
    pitch?.strikes ||
    pitch?.outs ||
    "Side"
  }): ${pitch?.type} ${pitch?.speed?.toFixed(1)}MPH (${
    isNil(pitch.inducedVerticalBreak)
      ? "-"
      : pitch.inducedVerticalBreak?.toFixed(1)
  }″ IVB) (${
    isNil(pitch.horizontalBreak) ? "-" : pitch.horizontalBreak?.toFixed(1)
  }″ HB) ${batterHandedness}`;
}

export const getPlayerMetricColor = (metric: string) => {
  const colorsByMetricGroup = {
    msk: "#79a3f3",
    rom: "#9f79c1",
    pwr: "#ec84b0",
    power: "#ec84b0",
    spd: "#f1c949",
    speed: "#f1c949",
    bcmp: "#f3ad65",
    bodyComp: "#f3ad65",
    body_comp: "#f3ad65",
    workload: "#ff414c",
    vis: "#87dab7",
    vision: "#87dab7",
    visl: "#87dab7",
    visn: "#87dab7",
    obu: "#9b913c",
  };

  // @ts-expect-error
  return colorsByMetricGroup[metric] || "#79a3f3";
};

export const getPlayerMetricBackgroundColor = (metric: string) => {
  const colorsByMetricGroup = {
    msk: "#EFF5FF",
    rom: "#FAF5FF",
    pwr: "#FFF4F9",
    power: "#FFF4F9",
    spd: "#FFFBF0",
    speed: "#FFFBF0",
    bcmp: "#FBE6D0",
    bodyComp: "#FBE6D0",
    vis: "#F8FFFC",
    visl: "#F8FFFC",
    visn: "#F8FFFC",
    vision: "#F8FFFC",
    obu: "#FFF9B8",
  };

  // @ts-expect-error
  return colorsByMetricGroup[metric] || "#79a3f3";
};

export const getPlayerMetricEasyName = (metric: string) => {
  const easyNameByMetricId = {
    msk: "MSK Strength Index",
    rom: "Range of Motion",
    pwr: "Power Index",
    power: "Power Index",
    spd: "Speed Index",
    speed: "Speed Index",
    bcmp: "Body Comp and Hydration",
    bodyComp: "Body Comp and Hydration",
    vis: "Vision Index",
    vision: "Vision Index",
    obu: "OBU: On-Base-U Screen",
  };

  // @ts-expect-error
  return easyNameByMetricId[metric] || metric;
};

export const getMetricIdByEasyName = (metric: string) => {
  const metricIdByEasyName = {
    "MSK Strength Index": "msk",
    "Range of Motion": "rom",
    ROM: "rom",
    "Power Index": "power",
    "Speed Index": "speed",
    "Body Comp Index": "bodyComp",
    "Vision Index": "vision",
  };

  const currentKey =
    Object.keys(metricIdByEasyName).find((it) => it.includes(metric)) || metric;

  // @ts-expect-error
  return metricIdByEasyName[currentKey] || metric;
};

export enum Side {
  Left = "left",
  Right = "right",
}

const adaptSide = (side: string) =>
  side === "L" ? Side.Left : side === "R" ? Side.Right : side;

export const getSide = (side: string | Side) => {
  const adaptedSide = adaptSide(side);

  if (adaptedSide?.toLowerCase().includes(Side.Left)) {
    return Side.Left;
  }

  if (adaptedSide?.toLowerCase().includes(Side.Right)) {
    return Side.Right;
  }

  return;
};

export const filterBySide = (
  data: any[],
  side: Side = Side.Right,
  options: { strict: boolean } = { strict: true }
) => {
  const oppositeSide = getOppositeSide(side);
  return data.filter(({ metric }: any) => {
    const extraMetricCondition = options.strict
      ? false
      : !metric?.includes(side) && !metric?.includes(oppositeSide);
    return metric?.includes(side) || extraMetricCondition;
  });
};

export const getOppositeMetric = (metric: string) => {
  const metricParts = metric.split("_");
  const side = last(metricParts) || "left";
  return metric.replace(
    `_${side}`,
    `_${getOppositeSide(side)?.toString().toLowerCase() || ""}`
  );
};
export const getOppositeSide = (side?: string | Side) => {
  if (!side) {
    return;
  }

  const adaptedSide = adaptSide(side);

  if (adaptedSide?.includes(Side.Left)) {
    return Side.Right;
  }

  if (adaptedSide?.includes(Side.Right)) {
    return Side.Left;
  }

  return;
};

export const minifySide = (side: string = "") => side.charAt(0).toUpperCase();

export const playerDashMetricsColors = [
  "#79a3f3",
  "#9f79c1",
  "#ec84b0",
  "#f1c949",
  "#f3ad65",
  "#87dab7",
];

export const calculateRange = (
  operation: "+" | "-",
  amount: number,
  mean: number,
  stdDev: number
) => {
  const operations = {
    "+": (a: number, b: number) => sum([a, b]),
    "-": (a: number, b: number) => subtract(a, b),
  };

  const currentOperation = operations[operation];

  return currentOperation(mean, amount * stdDev);
};

export const filterEmptyEntries = (data: any[]) =>
  data.filter((it) => !isNil(it?.metric));

export const sidelessMetric = (metric: string, prefix = "") =>
  metric.replace(`${prefix}left`, "").replace(`${prefix}right`, "");

export const formatToTableValue = (value: number, suffix: string) =>
  `${value >= 0 ? "+" : "-"}${Math.abs(value)}${suffix}`;

export const percentageChange = (value: number, previousValue: number) =>
  round(((value - previousValue) / previousValue) * 100);

export const UNDER_COLOR = "#eb9d4d";
export const ABOVE_COLOR = "#7ccc5a";
export const EQUAL_COLOR = "#e1e2e2";

export const fixColor = (text: string) =>
  text === "-" ? "black" : startsWith(text, "-") ? UNDER_COLOR : ABOVE_COLOR;

export const hasSide = (metricId: string) =>
  metricId !== sidelessMetric(metricId);

export const filterMetricsBySide = (
  metricId: string,
  data: any[],
  side: Side | undefined
) => {
  return data.map((currentMetric: any) => {
    return {
      ...omit(currentMetric, "metrics"),
      metric: filterBySide(currentMetric.metrics, side, {
        strict: hasSide(metricId),
      })[0],
    };
  });
};

export const getDateArrayData = (
  data: any,
  dataIndexing: ExpandedMetricsDataIndexing,
  extra?: string
) =>
  sortBy(
    data.filter((it: any) => !isNil(it.metric?.value)),
    "date"
  ).map((it: any, i: number) =>
    [
      dataIndexing === ExpandedMetricsDataIndexing.Date
        ? dayjs(it.date, "YYYY-MM-DD").valueOf()
        : i + 1,
      it.metric?.value,
    ].concat(extra ? it[extra] : [])
  );

export function sidedMetricLabelToDominant(
  value?: string,
  dominantSide = Side.Left
) {
  const dominantLabel = dominantSide === Side.Left ? "(L)" : "(R)";
  const nonDominantLabel = dominantSide === Side.Left ? "(R)" : "(L)";
  return (value ?? "")
    .replace(dominantLabel, "(Dom)")
    .replace(nonDominantLabel, "(Non-Dom)");
}

export function dominantMetricLabelToSided(value?: string) {
  return (value ?? "").replace("(Dom)", "(L)").replace("(Non-Dom)", "(R)");
}

export const flipMetricLabelSide = (metricLabel?: string) =>
  metricLabel?.includes("(L)")
    ? metricLabel?.replace("(L)", "(R)")
    : metricLabel?.replace("(R)", "(L)");

export const flipMetricVariableNameSide = (metricVariableName?: string) =>
  metricVariableName?.includes("left")
    ? metricVariableName?.replace("left", "right")
    : metricVariableName?.replace("right", "left");

function sideInitialToSide(initial: string): Side {
  return initial === "L" ? Side.Left : Side.Right;
}

export function playerHandedness(player?: Player) {
  const defaultSide = "R";
  const position = player?.position || player?.primaryPosition;
  if (PITCHER_TYPES.includes(position ?? "")) {
    return sideInitialToSide(player?.throws ?? defaultSide);
  }

  // If a position player is a switch hitter (Both), we can determine dominance using their “Throws” R/L status.
  return sideInitialToSide(
    (player?.bats === "SH" ? player?.throws : player?.bats) ?? defaultSide
  );
}

interface GetIconAndColorParams {
  value: number;
  mean?: number;
  stdDev?: number;
}

interface GetIconAndColorResponse {
  color: string;
  icon: "higher" | "lower" | "default";
}

export const getIconAndColor = ({
  value,
  mean,
  stdDev,
}: GetIconAndColorParams): GetIconAndColorResponse | undefined => {
  if (isNaN(value) || mean === undefined || stdDev === undefined) {
    return;
  }

  if (value <= mean - 2 * stdDev) {
    return { color: "#1cb0ff", icon: "lower" };
  }
  if (value > mean - 2 * stdDev && value <= mean - stdDev) {
    return { color: "#bae7ff", icon: "lower" };
  }
  if (value > mean + stdDev && value < mean + 2 * stdDev) {
    return { color: "#ffccc7", icon: "higher" };
  }
  if (value >= mean + 2 * stdDev) {
    return { color: "#fd3522", icon: "higher" };
  }

  return { color: "#e1e2e2", icon: "default" };
};

export const formatNumWithDecimals = (value: any, decimals = 0) =>
  !isNil(value) ? Number(Number(value).toFixed(decimals)).toLocaleString() : "";

type PlayerSummaryValue = number | string | undefined;

export const checkPlayerSummaryValue = (value: PlayerSummaryValue) =>
  !isNil(value) && Number(value) > 0 && value !== "-";

export const getPlayerSummaryValueOrDefault = (value: PlayerSummaryValue) =>
  checkPlayerSummaryValue(value) ? Number(Number(value).toFixed(0)) : "-";

export const isROM = (metricLabel: string) => metricLabel === "Range of Motion";

export const truncateNumberToDecimals = (
  number?: number | null | string,
  decimals = 0
) => Number(Number(number).toFixed(decimals));

export const pitchTypesByPriority = [
  "4FB",
  "2FB",
  "CT",
  "SW",
  "SL",
  "CB",
  "CH",
  "SF",
];

export function parseMetricValue(
  value: string,
  decimals = 1,
  prop = "shortLabel",
  isHydration = false
) {
  // Deal with NaN coming from the API
  if (value === "NaN") {
    return null;
  }

  return isStringNumber(value)
    ? truncateNumberToDecimals(+value, decimals)
    : isHydration
    ? getHydrationLabel(value)
    : spineMobilityProp(value, prop, value);
}

export const getColorByDaysOld = (daysOld: number, thresholds = [60, 60]) => {
  const [first, second] = thresholds;

  if (daysOld < first) {
    return "var(--ptd-dashboard-green)";
  }
  if (daysOld >= first && daysOld < second) {
    return "var(--ptd-dashboard-yellow)";
  }
  if (daysOld >= second) {
    return "var(--ptd-dashboard-red)";
  }

  return "";
};

export const getIsPriorityNote = (id: string) => {
  const sections = id.split("_");

  return sections?.[2] === "priority" && sections?.[4] === "notes";
};

const zeroAsNullMetrics = [
  "gps_max_acceleration",
  "gps_max_deceleration",
  "gps_max_velocity",
];

export function shouldTreatZeroAsNull(metricKey?: string) {
  return !isNil(metricKey) && zeroAsNullMetrics.includes(metricKey);
}

export function treatAsNullIfNeeded<T>(
  metricKey?: string,
  value?: T
): T | undefined | null {
  if (isNil(metricKey) || isNil(value)) {
    return value;
  }

  return shouldTreatZeroAsNull(metricKey) && +value === 0 ? null : value;
}

export function flipSideIfNeeded(value: string, player?: Player) {
  const shouldFlipSide = playerHandedness(player) === Side.Right;
  return shouldFlipSide ? flipMetricVariableNameSide(value) : value;
}

export const removeSideIndicator = (label: string) =>
  label?.replace("(L) ", "")?.replace("(R) ", "");

const sideIndicator = (side: Side) => `(${minifySide(side)})`;

export const dominanceIndicator = (
  metricLabel: string,
  dominantSide = Side.Left
) => {
  if (metricLabel?.includes(sideIndicator(dominantSide))) {
    return { short: "Dom", full: "Dominant" };
  }
  if (
    metricLabel?.includes(
      sideIndicator(getOppositeSide(dominantSide) ?? Side.Right)
    )
  ) {
    return { short: "Non-Dom", full: "Non-Dominant" };
  }
  return { short: "", full: "" };
};

export const dominanceLabel = (metricLabel: string, dominantSide: Side) => {
  const indicator = dominanceIndicator(metricLabel, dominantSide).full;
  const side =
    indicator === "Dominant" ? dominantSide : getOppositeSide(dominantSide);
  return `${indicator} (${minifySide(side)})`;
};

type FormatSelfDataParams = {
  metric: any;
  data: any[];
  visible?: boolean;
  color?: string;
  dashStyle?: string;
};

export const formatSelfData = ({
  metric,
  data,
  visible = true,
  color = "var(--self-accent-color)",
  dashStyle,
}: FormatSelfDataParams) => [
  {
    ...metric,
    label: `Self ${metric?.label}`,
    color,
    data,
    visible,
    dashStyle,
    lineColor: color,
  },
];

export const shouldFetchSelfCompData = (
  player: any,
  selfCompOptions: SelfCompSelectedValue
) =>
  !isNil(player?.id) &&
  !isNil(selfCompOptions?.payload?.date) &&
  !isNil(selfCompOptions?.payload?.pitchType);

export const flattenReverseOrDefault = (
  data: any[],
  secondaryData: any[],
  showReversed = false
) => {
  const allData = [data, secondaryData];

  return flatten(showReversed ? reverse(allData) : allData);
};

export const isRegressionValid = (regressionData: any) => {
  const r2 = regressionData?.r2;
  const points = regressionData?.points;
  const isR2Valid = isNumber(r2) && !isNaN(r2) && isFinite(r2);
  const arePointsValid = !isEmpty(points);

  return isR2Valid && arePointsValid;
};

interface GetRegressionDataParams {
  regressionNumber?: number;
  visibleChartSeries: any;
  keys: any;
  props: any;
}

export const getRegressionData = ({
  regressionNumber,
  visibleChartSeries,
  keys,
  props,
}: GetRegressionDataParams) => {
  if (isNil(regressionNumber)) {
    return {
      currentKey: undefined,
      visible: false,
      disabled: false,
    };
  }

  const currentKey = !isNil(regressionNumber)
    ? keys[regressionNumber]
    : undefined;
  const regressionData = currentKey
    ? currentKey === "fullRegression"
      ? props?.data?.regression
      : props[currentKey]
    : undefined;
  const disabled = !isRegressionValid(
    regressionData?.result ? regressionData.result : regressionData
  );
  const visible = disabled
    ? false
    : visibleChartSeries && currentKey
    ? visibleChartSeries[currentKey]
    : false;

  return {
    currentKey,
    visible,
    disabled,
  };
};

export const formatRegression = (it?: Result) => {
  if (isNil(it) || isEmpty(it)) {
    return "-";
  }
  const { string = "", r2 = 0 } = it;
  const yValue = string?.split("=")[1] || "";
  const [leftSide, rightSide] = yValue?.split(" + ");
  const mappedLeftSide = Number(leftSide?.replace("x", "")).toExponential(2);
  const mappedRightSide = Math.abs(Number(rightSide)).toExponential(2);
  const sign = Number(rightSide) < 0 ? "-" : "+";
  const r = Math.sqrt(r2).toFixed(2);

  return `${mappedLeftSide}x ${sign} ${mappedRightSide}  R=${r}`;
};

export const formatRegressionNumber = (it?: number) => it?.toFixed(1);

export const getBodyPartColumns = (motionType: MotionType) => {
  const columns = {
    [MotionType.Pitching]: PITCHING_BODY_PARTS_EVENTS,
    [MotionType.Batting]: BATTING_BODY_PARTS_EVENTS,
  };

  return columns[motionType];
};

export const isLastCurrent = (data: any[], index: number) =>
  indexOf(data, last(data)) === index;

export const filterDataBySection = (data: any[], section: string) =>
  data?.filter(
    (metric) =>
      metric.sections.includes(section) || metric.id === "time_from_br"
  );

export const toVideoAnalaisysTableDataSource = (
  discreteData: DiscreteDataItem[],
  secondaryDiscreteData: DiscreteDataItem[]
) =>
  discreteData.map((discreteMetric) => {
    const currentSecondary = secondaryDiscreteData?.find(
      (it) => it?.key === discreteMetric?.key
    );

    const mappedSecondaryValue = !isNil(currentSecondary?.value)
      ? { secondaryValue: currentSecondary?.value }
      : {};

    return {
      metric: {
        value: discreteMetric?.value,
        label: (discreteMetric?.label || "").split("(")[0],
        decimals: discreteMetric?.decimals,
        unit: discreteMetric?.unit,
      },
      time: discreteMetric?.event,
      avgValue: {
        mean: discreteMetric?.mean,
        average: discreteMetric?.dataComp,
      },
      ...mappedSecondaryValue,
    };
  });

export const orderDataByEvent = (data: any[]) => {
  const allEvents = castArray(uniq(data?.map((it) => it.event)));

  return sortBy(data, (it) =>
    findIndex(allEvents, (event: any) => it?.event === event)
  );
};

export const groupData = (data: any[], metricsToTake: number): any[][] =>
  data.reduce((acc, curr, index) => {
    if (index % metricsToTake === 0) {
      acc.push([]);
    }

    acc[acc.length - 1].push(curr);

    return acc;
  }, []);

export const toScientificNotation = (
  number: string | number,
  fractionDigits: number = 2
) => Number(number).toExponential(fractionDigits);
