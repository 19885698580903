import React from "react";
import VideoPanelModal from "./components/VideoPanelModal/VideoPanelModal";
import VideoScrubber from "./components/VideoScrubber/VideoScrubber";
import { useVideoAnimationViewer } from "./hooks/useVideoAnimationViewer";
import BiomechanicsMetricsPanel from "./components/Metrics/BiomechanicsMetricsPanel";
import TrendsPanelModal from "./components/TrendsPanelModal/TrendsPanelModal";
import StuffPanelModal from "./components/TrendsPanelModal/StuffPanelModal";
import BattedBallPanelModal from "./components/TrendsPanelModal/BattedBallPanelModal";
import { TeamPlayer } from "./services/mockData";
import { MovementVideo } from "./MovementVideo";
import { LoadingOverlay } from "./components/common/MuiCommon";

export interface VideoAnimationViewerProps {
  jointCoordinates: any[];
  mainPlayer?: TeamPlayer;
  comparingJointCoordinates: any[];
  comparingPlayer?: TeamPlayer;
  videos: MovementVideo[];
  comparingVideos: MovementVideo[];
  standaloneViewer?: boolean;
  isSideBarOpen?: boolean;
  extraMovementsDetails?: any[];
  isFetchingMain: boolean;
  isFetchingComparing: boolean;
}

const VideoAnimationViewer = ({
  jointCoordinates,
  mainPlayer,
  comparingJointCoordinates,
  comparingPlayer,
  videos,
  comparingVideos,
  standaloneViewer = false,
  extraMovementsDetails = [],
  ...props
}: VideoAnimationViewerProps) => {
  const {
    wrapper,
    videoRefs,
    videoDuration,
    maxValueScrubb,
    setLoopVideo,
    loopVideo,
    segmentFrames,
    setSegmentFrames,
    shouldShowStadium,
    setShouldShowStadium,
    syncWorldAndVideo,
    videoScrubberProps,
    battingMode,
  } = useVideoAnimationViewer({
    jointCoordinates,
    mainPlayer,
    comparingJointCoordinates,
    comparingPlayer,
    videos,
    comparingVideos,
    standaloneViewer,
    extraMovementsDetails,
    ...props,
  });

  return (
    <>
      <BiomechanicsMetricsPanel
        handleScrubber={videoScrubberProps.handleScrubber}
        keyframesData={videoScrubberProps.keyframesData}
      />
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <LoadingOverlay
          opacity={0.08}
          size="large"
          isLoading={props.isFetchingMain || props.isFetchingComparing}
        />
        <div style={{ height: "100%", left: 80 }} ref={wrapper}>
          {!standaloneViewer && (
            <VideoPanelModal
              videoRefs={videoRefs}
              videos={videos}
              comparingVideos={comparingVideos}
              syncWorldAndVideo={syncWorldAndVideo}
            />
          )}
          {battingMode ? <BattedBallPanelModal /> : <StuffPanelModal />}
          <TrendsPanelModal disabled={battingMode} />
          <VideoScrubber
            maxValueScrubb={maxValueScrubb}
            videoDuration={videoDuration}
            setLoopVideo={setLoopVideo}
            loopVideo={loopVideo}
            segmentFrames={segmentFrames}
            setSegmentFrames={setSegmentFrames}
            shouldShowStadium={shouldShowStadium}
            onShouldShowStadiumChange={setShouldShowStadium}
            {...videoScrubberProps}
          />
        </div>
      </div>
    </>
  );
};

export default VideoAnimationViewer;
